import { Button, Input, Card, Col, Row } from '@bdo/kitchensink';
import React from 'react';
import CaptureForm from './CaptureForm';

export default function Update({ capture, disableTabs, setCapture }) {

    const externalUrl = process.env.REACT_APP_EXTERNAL_CAPTURE_URL;
    const shareALink = `${externalUrl}${capture.id}`;
    const embedCode = () => {
        return `<script src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.0/jquery.min.js" type="text/javascript"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.1.1/iframeResizer.min.js" type="text/javascript"></script>
<iframe id="external-capture" scrolling="no" style="overflow: hidden; width: 100%; border: none;" onload="iFrameResize({heightCalculationMethod: 'max'})" src="${externalUrl}${capture.id}"></iframe>`;
    }  

    return (

        <div className='content'>
            <Row>
                <Col span={24}>
                    <CaptureForm 
                    title={""}
                    isUpdating={true}
                    capture={capture}   
                    disableTabs = {disableTabs}
                    setCapture = {setCapture}             
                    />                    
                    <div className='additionalContent'>
                        <Card title="Share a link" className='flex-card'>
                            <Input value={shareALink}></Input>
                            <Button type="primary" iconName="Copy" onClick={() => { navigator.clipboard.writeText(shareALink) }}>Copy to clipboard</Button>
                        </Card>
                    </div>
                    <div className='additionalContent'>
                        <Card title="Embed in a webpage" className='embedInWebpageContent'>
                            <pre style={{ height: 150 }}>
                                {embedCode()}
                            </pre>
                            <Button type="primary" iconName="Copy" onClick={() => { navigator.clipboard.writeText(embedCode()) }}>Copy to clipboard</Button>
                        </Card>
                    </div>
                
                </Col>
            </Row>
        </div>


    );
}