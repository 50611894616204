import { Row, Col, Card } from '@bdo/kitchensink';
import React, { useState, useEffect } from 'react';
import { apiRoutes, buildApiRoute } from '../../config/apiRoutes';
import { useLogging } from '../../hooks/useLogging';
import { useCaptureApi } from '../../hooks/useCaptureApi';
import PasscodeForm from './PasscodeForm';
import * as Shared from '../Shared';
import { passcodeActions, captureManageTabs } from '../../config/enum';
import { generalMessage } from '../../config/errorMessages';

export default function Create({ view, setView }) {

    const passcodeId = view.id;

    const states = {
        error: 'Error',
        notFound: 'NotFound',
        loading: 'Loading',
        accessDenied: 'AccessDenied',
        loaded: 'Loaded'
    }

    const [componentState, setComponentState] = useState(states.loading);
    const { apiGet, apiPost } = useCaptureApi();
    const [errorMessage, setErrorMessage] = useState('');
    const [metaData, setMetaData] = useState({});
    const [passcode, setPasscode] = useState({});
    const logging = useLogging();

    async function onSave(values) {
        try {

            setErrorMessage(null);
            var payload = {
                id: passcode.id,
                hasMetaData: metaData.hasMetaData,
                metaData: null,
                passcode: passcode.passcode,
                ...values
            }

            const result = await apiPost(buildApiRoute(apiRoutes.passcodes.update), payload);

            if (result && result.status === 200) {
                setView({ tab: captureManageTabs.Passcodes, action: passcodeActions.List });
                return;
            } else if (result && result.status === 400) {
                setErrorMessage(result.data);
            } else {
                setErrorMessage(generalMessage.Default);
                logging.error("Failed to create passcode, non success status code", { result: result });
            }
        }
        catch (ex) {
            setErrorMessage(generalMessage.Default);
            logging.error("Failed to create passcode, axios exception", { exception: ex });
        }

        return false;
    }

    useEffect(() => {
        async function fetchData() {
            try {
                setComponentState(states.loading);

                const passcodeResult = await apiGet(buildApiRoute(apiRoutes.passcodes.view, { id: passcodeId }));

                if (passcodeResult && passcodeResult.status === 200) {
                    setPasscode(passcodeResult.data);
                } else if (passcodeResult && passcodeResult.status === 404) {
                    setComponentState(states.notFound);
                    return;
                } else if (passcodeResult && (passcodeResult.status === 401 || passcodeResult.status === 403)) {
                    setComponentState(states.accessDenied);
                    return;
                } else {
                    setComponentState(states.error);
                    logging.error("Unable to load metadata for passcode form, non success status code.", { passcodeId: passcodeId, result: passcodeResult });
                    return;
                }

                const metaDataResult = await apiGet(buildApiRoute(apiRoutes.metaData.passcode, { captureId: passcodeResult.data.captureId, passcodeId: passcodeResult.data.id }));

                if (metaDataResult && metaDataResult.status === 200) {
                    setMetaData(metaDataResult.data);
                    setComponentState(states.loaded);
                    return;
                }
                if (metaDataResult && metaDataResult.status === 404) {
                    setComponentState(states.notFound);
                    return;
                }
                if (metaDataResult && (metaDataResult.status === 401 || metaDataResult.status === 403)) {
                    setComponentState(states.accessDenied);
                    return;
                }

                setComponentState(states.error);
                logging.error("Unable to load metadata for passcode form, non success status code.", { captureId: passcodeResult.data.captureId, result: metaDataResult });
            }
            catch (ex) {
                setComponentState(states.error);
                logging.error("Unable to load metadata for passcode form, axios exception.", { passcodeId: passcodeId, exception: ex });
            }
        }
        fetchData();
    }, [passcodeId]);

    const renderState = {
        [states.loading]: () => {
            return (<Shared.Loading />);
        },
        [states.error]: () => {
            return (<Shared.Error />);
        },
        [states.notFound]: () => {
            return (<Shared.NotFound />);
        },
        [states.accessDenied]: () => {
            return (<Shared.AccessDenied />);
        },
        [states.loaded]: () => {
            return (
                <div className='content' style={{ paddingTop: '30px' }}>
                    <Row>
                        <Col span={24}>
                            <Card title="Update Passcode">
                                <PasscodeForm
                                    errorMessage={errorMessage}
                                    onSave={onSave}
                                    hasMetaData={metaData.hasMetaData}
                                    templateSchema={metaData.template}
                                    templateData={metaData.data}
                                    passcode={passcode}
                                    setView={setView}
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>
            );
        },
    }

    // Render
    return (renderState[componentState]());
}