import { Button } from '@bdo/kitchensink';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function BackButton({ url = '/', type = 'danger', iconName = 'Back', ...props }) {

    const navigate = useNavigate();

    function handleBack() {
        const getParameters = new URLSearchParams(window.location.search);
        var backUrl = getParameters.get('backUrl');
        if (backUrl) {
            navigate(backUrl);
        } else {
            navigate(url);
        }
    }

    return (
        <Button
            type={type}
            iconName={iconName}
            onClick={handleBack}
            {...props}
        >
            { props.children }
        </Button>
    );
}
