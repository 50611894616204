import { Button, Input, Form, Switch, Alert, Card, Col, Row } from '@bdo/kitchensink';
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { useCaptureApi } from '../../hooks/useCaptureApi';
import { apiRoutes, buildApiRoute } from '../../config/apiRoutes';
import { appRoutes, buildAppRoute } from '../../config/appRoutes';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Select from 'react-select'
import { useLogging } from '../../hooks/useLogging';
import Moment from "moment";

export default function ManageTemplate() {
    const navigate = useNavigate();
    const { apiGet, apiPost } = useCaptureApi();
    const { templateId } = useParams();
    const defaultTemplateSettings =  {
        id:'',
        createdBy: '',    
        createdDate:'',
        createdFormated:'',
        isPublic: false,
        modifiedBy: '',
        modifiedDate:'',
        modifiedFormated:'',
        owners: [],
        ownersArr: [],        
        members: [],
        membersArr: [],
        approvers:[],
        approversArr:[]
      }
    const [displayMembers, setDisplayMembers] = useState(true);
    const [templateSettings, setTemplateSettings] = useState(defaultTemplateSettings);
    const [hasError, setHasError] = useState(false);    
    const [isSaving, setIsSaving] = useState(false);
    const [captureApprovers, setCaptureApprovers] = useState([]);

    const logging = useLogging();

    useEffect(() => {
        async function fetch() {
            if (templateId) 
            {       
                const response = await apiGet(buildApiRoute(apiRoutes.templateSettings.get, { templateId: templateId }));
                if (response && response.status === 200) {
                    Moment.locale("en")
                    setTemplateSettings( 
                    {
                        ...defaultTemplateSettings, 
                        ...response.data,
                        ownersArr: response.data.owners.map( (x) =>({ value: x.id, label: x.name} )),
                        membersArr: response.data.members.map( (x) =>({ value: x.id, label: x.name} )),
                        approversArr:response.data.approvers.map( (x) =>({ value: x.id, label: x.name} )),
                        createdFormated : Moment(response.data.createdDate).format("DD-MM-YYYY hh:mm"),
                        modifiedFormated : Moment(response.data.modifiedDate).format("DD-MM-YYYY hh:mm")

                    });
                    setDisplayMembers(!response.data.isPublic);
                }
                else
                {
                    setHasError(true);
                }                
            }
        }
        fetch();
    }, []);

    useEffect(() => {
        async function fetch() {
            if (templateId) 
            {       
                const response = await apiGet(buildApiRoute(apiRoutes.MSGraph.getcaptureapprovers));
                if (response && response.status === 200) {
                    Moment.locale("en")
                    setCaptureApprovers(...captureApprovers, response.data.map( (x) =>({ value: x.id, label: x.name} )));
                   
                }
                else
                {
                    setHasError(true);
                }                
            }
        }
        fetch();
    }, []);


    if (hasError) {
        return (
            <p>Error communicating with the server, try refreshing the page.</p>
        );
    }
    async function onFinish(values) {
        try {
            setIsSaving(true);
            setHasError(false)

            var payload = values;
            payload.owners = values.ownersArr.map( (x) => ({ id: x.value, name: x.label}));
            if (!values.isPublic)
            { 
                payload.members =  values.membersArr.map( (x) => ({ id: x.value, name: x.label}));
            }
            payload.approvers = values.approversArr.map( (x) => ({ id: x.value, name: x.label}));

            const result = await apiPost(buildApiRoute(apiRoutes.templateSettings.update), payload);

            if (result && result.status === 200) {
                navigate(buildAppRoute(appRoutes.template.list));
                return;
            }
            setHasError(true);
            logging.error("Failed to update template settings, non success status code", { result: result });
        }
        catch (ex) {
            setHasError(true);
            logging.error("Failed to update template settings, non success status code", { exception: ex });
        }

        setIsSaving(false);
    }


    const promiseOptionsOwners = async (inputValue) => {
        if (inputValue.length > 2){
            var options = await apiGet(buildApiRoute(apiRoutes.MSGraph.getGroupsAndUsers, { startWithValue: inputValue })); 
            return options.data.map( (x) => ({ value: x.id, label: x.name}));
        }
    }
    const promiseOptionsMembers = async (inputValue) => {
        if (inputValue.length > 2){
            var options = await apiGet(buildApiRoute(apiRoutes.MSGraph.getGroupsAndUsers, { startWithValue: inputValue })); 
            return options.data.map( (x) => ({ value: x.id, label: x.name}));
        }
    }

    

    return (
        <>
        <h1>Manage settings</h1>
        <div className='content' style={{ paddingTop: '30px' }}>
            <Row>
                <Col span={24}>
                    <Card>
                        {hasError && <Alert type="error" message="There was an issue communicating with the server, please try again." className="mb-3" />}

                        {templateSettings.id.length > 0 && 
                        <Form layout='vertical' name='templateSettings' onFinish={onFinish} initialValues= {templateSettings} >   
                            <Form.Item name={'id'} hidden>
                                <Input />
                            </Form.Item>                         
                            <Form.Item name={'createdBy'} label='Created By' >
                                <Input disabled={true} />
                            </Form.Item>
                            <Form.Item name={'createdFormated'} label='Created' >
                                <Input disabled={true} />
                            </Form.Item>
                            <Form.Item name={'modifiedBy'} label='Modified by' >
                                <Input disabled={true} />
                            </Form.Item>
                            <Form.Item name={'modifiedFormated'} label='Last modified' >
                                <Input disabled={true} />
                            </Form.Item>   
                            <>                       
                                <label>Owners</label>
                                <p><i>Owners are able to delegate access to the template, and modify the questions.</i></p> 
                                <Form.Item name={'ownersArr'} >                                   
                                    <AsyncSelect  isMulti cacheOptions loadOptions={promiseOptionsOwners} closeMenuOnSelect={false} />
                                </Form.Item> 

                                <label>Approvers</label>
                                <p><i>Approvers will be notified of your approval request and will be responsible of approving this template.</i></p>
                                <Form.Item name={'approversArr'}>
                                    <Select isMulti options={captureApprovers} />
                                </Form.Item>
                            </>                                                         
                            <Form.Item name={'isPublic'} label='Available to everyone' valuePropName='checked' >
                                <Switch checkedChildren='Yes' unCheckedChildren='No' onClick={ () => setDisplayMembers(prevState => !prevState)}/>
                            </Form.Item>
                            {displayMembers &&
                                <>                       
                                    <label>Members</label>
                                    <p><i>Members are able to use an approved template in a capture, without modifiying the questions.</i></p>
                                    <Form.Item name={'membersArr'}>                                                                                             
                                        <AsyncSelect  isMulti cacheOptions loadOptions={promiseOptionsMembers} closeMenuOnSelect={false} />
                                    </Form.Item>
                                </> 
                            }
                            <Form.Item>
                                <div className="d-flex flex-row justify-content-between">
                                    <Button type="default" onClick={() => navigate(buildAppRoute(appRoutes.template.list))}>
                                        Cancel
                                    </Button>
                                    <Button className="ms-3" type='success' iconName='Save' htmlType='submit' loading={isSaving}>
                                        Save
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                        }
                    </Card>
                </Col>
            </Row>
        </div>
        </>
    );
}
