import { Button, Form, Alert, Switch, Select, Input } from "@bdo/kitchensink";
import React, { useEffect, useState } from "react";
import { captureManageTabs, globalPortalActions } from "../../config/enum";
import MetaDataSurvey from "../shared/metaDataSurvey/MetaDataSurvey";
import { useGlobalPortalData } from "../../hooks/useGlobalPortalData";
import * as Shared from "../Shared";
import { EmailsValidator } from "../../config/formValidations";

export default function AssignmentForm({ errorMessage, onSetErrorMessage, onSave, hasMetaData = false, templateSchema = {}, templateData = {}, assignment = {}, setView, editMode }) {
  const validateMessages = {
    required: "${label} is required.", // eslint-disable-line no-template-curly-in-string
  };

  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [survey, setSurvey] = useState({});
  const [displayAssignToUsers, setDisplayAssignToUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const { GetClientsForUser, GetProjectsForClient, GetUsersForProject } = useGlobalPortalData();
  const [clientId, setClientId] = useState();
  const [displayForm, setDisplayForm] = useState(false);

  const [sendNotification, setSendNotification] = useState(assignment.sendCompletionNotification ? assignment.sendCompletionNotification : false);

  function onAfterRenderSurvey(sender, options) {
    setSurvey(sender);
  }

  async function GetClients() {
    let response = await GetClientsForUser();
    if (response && response.status === 200) {
      setClients(response.data);
    } else {
      onSetErrorMessage("Failed to fetch clients for the current user, non success status code");
    }
  }

  async function GetProjects(clientId) {
    try {
      setClientId(clientId);
      const response = await GetProjectsForClient(clientId);
      if (response && response.status === 200) {
        setProjects(response.data);
      } else {
        onSetErrorMessage("Failed to fetch projects for the current client, non success status code");
      }
    } catch (ex) {
      onSetErrorMessage("Failed to fetch projects for the current client, non success status code");
    }
  }

  async function GetUsers(projectId) {
    try {
      const response = await GetUsersForProject(clientId, projectId);
      if (response && response.status === 200) {
        setUsers(response.data);
      } else {
        onSetErrorMessage("Failed to fetch users for the current project, non success status code");
      }
    } catch (ex) {
      onSetErrorMessage("Failed to fetch users for the current project, non success status code");
    }
  }

  async function onClientChange(clientId) {
    if (clientId) {
      await GetProjects(clientId);
    }

    form.setFieldsValue({ ...form, projectId: "" });
  }

  async function onProjectChange(projectId) {
    if (projectId) {
      await GetUsers(projectId);
    }

    form.setFieldsValue({ ...form, assigneeEmailAddresses: [] });
  }

  async function onFinish(values) {
    setIsSaving(true);

    let payload = { ...values, memberfirmId: parseInt(process.env.REACT_APP_MF_ID) };
    if (hasMetaData && survey.hasErrors(true, true)) {
      setIsSaving(false);
    } else {
      let result = await onSave(payload);
      if (result === false) {
        setIsSaving(false);
      }
    }
  }

  useEffect(() => {
    async function UpdateCurrentAssignment() {
      try {
        const responseClients = await GetClientsForUser();
        if (responseClients && responseClients.status === 200) {
          setClients(responseClients.data);
          setClientId(assignment.clientId);
        } else {
          onSetErrorMessage("Failed to fetch clients, non success status code");
        }

        const responseProject = await GetProjectsForClient(assignment.clientId);
        if (responseProject && responseProject.status === 200) {
          setProjects(responseProject.data);
        } else {
          onSetErrorMessage("Failed to fetch projects for the current client, non success status code");
        }

        const responseUsers = await GetUsersForProject(assignment.clientId, assignment.projectId);
        if (responseUsers && responseUsers.status === 200) {
          setUsers(responseUsers.data);
        } else {
          onSetErrorMessage("Failed to fetch users for the current project, non success status code");
        }
        if (assignment.assignToIndividuals) {
          setDisplayAssignToUsers(true);
        }
        setDisplayForm(true);
      } catch (ex) {
        onSetErrorMessage("Failed to fetch the details for the current assignment, non success status code");
      }
    }
    // If Object has not keys we are creating a new assignment.
    if (Object.keys(assignment).length === 0) {
      GetClients();
      setDisplayForm(true);
    } else {
      UpdateCurrentAssignment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {errorMessage && <Alert type="error" message={errorMessage} className="mb-3" />}

      {!displayForm ? (
        <Shared.Loading />
      ) : (
        <>
          <Form form={form} layout="vertical" name="assignment" onFinish={onFinish} validateMessages={validateMessages} initialValues={assignment}>
            <Form.Item name={"title"} label="Survey Name" rules={[{ required: true }, { max: 200 }]}>
              <Input placeholder="The survey title will be revealed to the client or the audit entity." />
            </Form.Item>
            <Form.Item
              label="Portal"
              name="clientId"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                dropdownAlign={{ offset: [0, 0] }}
                onChange={onClientChange}
                allowClear
                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                disabled={editMode}
              >
                {clients?.map((client) => (
                  <Select.Option key={client?.clientId} value={client.clientId}>
                    {client.clientName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Project"
              name="projectId"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                dropdownAlign={{ offset: [0, 0] }}
                onChange={onProjectChange}
                allowClear
                filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                disabled={editMode}
              >
                {projects?.map((project) => (
                  <Select.Option key={project?.projectId} value={project.projectId}>
                    {project.projectName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name={"assignToIndividuals"} label="Assign capture to individuals?" valuePropName="checked">
              <Switch checkedChildren="Yes" unCheckedChildren="No" disabled={editMode} onClick={() => setDisplayAssignToUsers((prevState) => !prevState)} />
            </Form.Item>
            {displayAssignToUsers && (
              <>
                <Form.Item
                  label="Users"
                  name="assigneeEmailAddresses"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    dropdownAlign={{ offset: [0, 0] }}
                    allowClear
                    filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                    disabled={editMode}
                  >
                    {users?.map((user) => (
                      <Select.Option key={user?.emailAddress} value={user.emailAddress}>
                        {user.displayName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
            {/* #103711 reorder questions */}
            {hasMetaData && (
              <Form.Item name={["metaData"]} valuePropName="value" label="Metadata:">
                <MetaDataSurvey templateSchema={templateSchema} templateData={templateData} onAfterRenderSurvey={onAfterRenderSurvey} />
              </Form.Item>
            )}
            <Form.Item
              name={["sendCompletionNotification"]}
              label="Send a notification upon completion?"
              valuePropName="checked"
              extra="If selected an email will be sent to chosen email addresses when this passcode is completed. The email will contain a link to access the response associated with this passcode."
            >
              <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={setSendNotification} />
            </Form.Item>
            {sendNotification && (
              <Form.Item
                name={["completionEmailAddresses"]}
                label="Completion emails"
                rules={[{ required: sendNotification }, EmailsValidator]}
                extra="A comma (,) seperated list of email addresses that are the intended recipient(s) for a passcode completed notification. Maximum 10 emails."
              >
                <Input placeholder="employee.name@bdo.co.uk" type="email" multiple />
              </Form.Item>
            )}

            <Form.Item>
              <div className="d-flex flex-row justify-content-between">
                <Button type="default" onClick={() => setView({ tab: captureManageTabs.GlobalPortal, action: globalPortalActions.List })}>
                  Cancel
                </Button>
                <Button className="ms-3" iconName="Save" type="success" htmlType="submit" loading={isSaving}>
                  Save
                </Button>
              </div>
            </Form.Item>
          </Form>
        </>
      )}
    </>
  );
}
