import {
    Table as KitchensinkTable,
    Button,
    Badge,
    Modal,
    Alert,
    Switch
} from '@bdo/kitchensink';
import React, {useState} from 'react';
import {apiRoutes, buildApiRoute} from '../../config/apiRoutes';
import {passcodeActions, captureManageTabs, statuses} from '../../config/enum';
import {useLogging} from '../../hooks/useLogging';
import {useCaptureApi} from '../../hooks/useCaptureApi';
import {flattenObject, donwloadCSV, convertToCSV, downloadJson} from "../../hooks/useHelper";
import {useResponseExtractExternalData} from '../../hooks/useResponseExtractExternalData';
import {useResponseData} from '../../hooks/useResponseData';
import {Dropdown, Menu} from 'antd';
import Moment from "moment";
import * as SurveyPDF from 'survey-pdf';
import {FlatCustomQuestion} from '../../utilities/CustomPdfQuestion';

SurveyPDF.FlatRepository.getInstance().register("file-upload", FlatCustomQuestion);

export default function Table({
    isLoading,
    records,
    filterTerm,
    setView,
    captureId,
    templateName,
    displaySwitch,
    filterAnonymousResponses,
    showAnon,  
    ...props
}) {
    const {GetResponseExtractForResponseId, GetExtractForCaptureId} = useResponseExtractExternalData();
    const {GetPDFInfo} = useResponseData();
    const {apiGet} = useCaptureApi();
    const [isloading, setIsLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [passcode, setPasscode] = useState(null);
    const [hasError, setHasError] = useState(false);
    const logging = useLogging();

    const reopenResponse = async (passcodeId) => {
        try {
            const result = await apiGet(buildApiRoute(apiRoutes.passcodes.reopen, {id: passcodeId}));
            if (result && result.status === 200) {
                return true;
            }
            logging.error("Failed to reopen passcode, non success status code", {result: result});
        } catch (ex) {
            logging.error("Failed to reopen passcode, non success status code", {exception: ex});
        }

        return false;
    }

    const showModal = (record) => {
        setPasscode(record);
        setIsVisible(true);
    }

    const handleOk = async () => {
        setHasError(false);
        setIsLoading(true);
        var result = await reopenResponse(passcode.id);
        setIsLoading(false);

        if (result) {
            passcode.status = statuses.InProgress;
            setIsVisible(false);
        } else {
            setHasError(true);
        }
    }

    const handleCancel = () => {
        setPasscode(null);
        setHasError(false);
        setIsLoading(false);
        setIsVisible(false);
    }

    const handleExportcsv = async () => {
        try {
            const result = await GetExtractForCaptureId(captureId, showAnon);
            if (result && result.status === 200) {
                let mapped = result.data.map((data, idx) => (flattenObject(result.data[idx])))

                var headerArray = [];
                var unique = new Set();
                if (mapped) {
                    Object.keys(mapped).map((key) => {
                        Object.keys(mapped[key]).map((heading) => (unique.add(heading)))
                    });
                }
                headerArray = [... unique];
                let csvtest = convertToCSV(mapped, headerArray);
                let filename = `${templateName}_${
                    new Date().toISOString().replace(/(\.\d{3})|[^\d]/g, '')
                }.csv`.replace(/[/\\?%*:|"<> ]/g, '_');
                donwloadCSV(csvtest, filename);
                return;
            } else {
                logging.error("Failed to fetch json response, non success status code", {result: result});
            }

        } catch (ex) {
            logging.error("Failed to fetch json response, non success status code", {exception: ex});
        }
        alert("There was an error producing the CSV file. Please try again."); // TODO: Do something nicer than an alert here.
    }

    const handleExportjson = async () => {
        try {
            const result = await GetExtractForCaptureId(captureId, showAnon);
            if (result && result.status === 200) {
                const userData = result.data;

                let filename = `${templateName}_${
                    new Date().toISOString().replace(/(\.\d{3})|[^\d]/g, '')
                }.json`.replace(/[/\\?%*:|"<> ]/g, '_');
                downloadJson(userData, filename);
                return;
            } else {
                logging.error("Failed to fetch json response, non success status code", {result: result});
            }

        } catch (ex) {
            logging.error("Failed to fetch json response, non success status code", {exception: ex});
        }
        alert("There was an error producing the JSON file. Please try again."); // TODO: Do something nicer than an alert here.
    }
    const exportToPdf = async (responseId) => {
        try {
            const result = await GetPDFInfo(responseId);
            if (result && result.status === 200) {
                var pdfInfo = result.data;
                var options = {
                    fontSize: 10,
                    margins: {
                        left: 10,
                        right: 10,
                        top: 10,
                        bot: 10
                    },
                    format: [
                        210, 297
                    ],
                    haveCommercialLicense: true
                };
                var surveyPDF = new SurveyPDF.SurveyPDF(pdfInfo.templateData, options);
                surveyPDF.data = pdfInfo.userData;
                surveyPDF.mode = "display";
                let filename = `${
                    pdfInfo.templateTitle
                }_v${
                    pdfInfo.templateVersion
                }_${
                    pdfInfo.passcodeReference ? pdfInfo.passcodeReference : 'anonymous'
                }_${
                    new Date().toISOString().replace(/(\.\d{3})|[^\d]/g, '')
                }.pdf`.replace(/[/\\?%*:|"<> ]/g, '_');
                surveyPDF.save(filename);

                return;
            }
            logging.error("Failed to fetch pdf response, non success status code", {result: result});
        } catch (ex) {
            logging.error("Failed to fetch pdf response, non success status code", {exception: ex});
        }
        alert("There was an error producing the PDF file. Please try again."); // TODO: Do something nicer than an alert here.
    }
    const exportToJSON = async (responseId) => {
        try {
            const result = await GetResponseExtractForResponseId(responseId);
            if (result && result.status === 200) {
                let filename = `${
                    result.data.surveyTitle
                }_${
                    new Date().toISOString().replace(/(\.\d{3})|[^\d]/g, '')
                }.json`.replace(/[/\\?%*:|"<> ]/g, '_');
                downloadJson(result.data, filename)
                return;
            } else {
                logging.error("Failed to fetch json response, non success status code", {result: result});
            }
        } catch (ex) {
            logging.error("Failed to fetch json response, non success status code", {exception: ex});
        }
        alert("There was an error producing the JSON file for this record.");
    }
    const exportToCSV = async (responseId,) => {
        try {
            const result = await GetResponseExtractForResponseId(responseId);
            if (result && result.status === 200) {
                let mappedData = flattenObject(result.data);
                const dataArray = [];
                dataArray.push(mappedData)
                var headerArray = [];
                if (dataArray) {
                    headerArray = Object.keys(dataArray[0]);
                }
                let csvtest = convertToCSV(dataArray, headerArray);
                let filename = `${
                    result.data.surveyTitle
                }_${
                    new Date().toISOString().replace(/(\.\d{3})|[^\d]/g, '')
                }.csv`.replace(/[/\\?%*:|"<> ]/g, '_');
                donwloadCSV(csvtest, filename);
                return;
            } else {
                logging.error("Failed to fetch json response, non success status code", {result: result});
            }

        } catch (ex) {
            logging.error("Failed to fetch json response, non success status code", {exception: ex});
        }
        alert("There was an error producing the JSON file."); // TODO: Do something nicer than an alert here.
    }
 
    const columns = [
        {
            title: 'Reference name',
            key: 'tag',
            dataIndex: 'tag',
            textWrap: "word-break"
        },
        {
            title: 'Recipients',
            key: 'recipientEmailAddresses',
            dataIndex: 'recipientEmailAddresses',
            textWrap: "word-break"
        },
        {
            title: 'Last modified',
            key: 'modifiedDate',
            textWrap: "word-break",
            render: (record) => {
                Moment.locale("en");
                return <> {Moment(record.modifiedDate).format("DD-MM-YYYY hh:mm")} </>;
            }
        },
        {
            title: 'Status',
            key: 'id',
            width: "120px",
            render: (record) => {
                let output = null;
                if (record.status === statuses.NotStarted) {
                    output = <Badge status="error"
                        text={
                            statuses.NotStarted
                        }/>
                }
                if (record.status === statuses.InProgress) {
                    output = <Badge status="processing" text={statuses.InProgress} />
                }
                if (record.status === statuses.Complete) {
                    output = <Badge status="success"
                        text={
                            statuses.Complete
                        }/>
                }

                return(output);
            }
        }, {
            title: 'Actions',
            key: 'actions',
            width: "100px",
            render: (record) => {
                const menu = (
                    <Menu> {
                        record.status === statuses.Complete && <Menu.Item key={`MI-7`}
                            onClick={
                                () => setView({tab: captureManageTabs.Passcodes, action: passcodeActions.View, id: record.responseId})
                        }>
                            <Button size='small' type='primary' iconName='View'
                                style={
                                    {marginRight: 5}
                                }/>
                            <span> View response</span>
                        </Menu.Item>
                    }
                        {
                        record.tag !== "Anonymous" &&  record.status !== statuses.Complete && <Menu.Item key={`MI-4`}
                            onClick={
                                () => {
                                    setView({tab: captureManageTabs.Passcodes, action: passcodeActions.Update, id: record.id});
                                }
                        }>
                            <Button size='small' type='primary' iconName='Edit'
                                style={
                                    {marginRight: 5}
                                }/>
                            <span> Edit</span>
                        </Menu.Item>
                    }
                        {
                        record.tag !== "Anonymous" && <Menu.Item key={`MI-1`}
                            onClick={
                                () => navigator.clipboard.writeText(record.passcode)
                        }>
                            <Button size="small" type="primary" iconName="Copy" 
                            style={
                                {marginRight: 5}
                            }/>
                            <span> Copy to clipboard</span>
                        </Menu.Item>
                    }
                        {
                        record.status === statuses.Complete && <Menu.Item key={`MI-9`}
                            onClick={
                                async (e) => await exportToPdf(record.responseId)
                        }>
                            <Button size='small' type='primary' iconName='DownloadDocument'
                                style={
                                    {marginRight: 5}
                                }/>
                            <span> Export to PDF</span>
                        </Menu.Item>
                    }
                        {
                        record.status === statuses.Complete && <Menu.Item key={`MI-10`}
                            onClick={
                                async (e) => await exportToJSON(record.responseId)
                        }>
                            <Button size='small' type='primary' iconName='DownloadDocument'
                                style={
                                    {marginRight: 5}
                                }/>
                            <span> Export to JSON</span>
                        </Menu.Item>
                    }
                        {
                        record.status === statuses.Complete && <Menu.Item key={`MI-11`}
                            onClick={
                                async (e) => await exportToCSV(record.responseId)
                        }>
                            <Button size='small' type='primary' iconName='DownloadDocument'
                                style={
                                    {marginRight: 5}
                                }/>
                            <span> Export to CSV</span>
                        </Menu.Item>
                    }
                        {
                        record.status === statuses.Complete && record.tag !== "Anonymous" && <Menu.Item key={`MI-6`}
                            onClick={
                                () => showModal(record)
                        }>
                            <Button size='small' type='primary' iconName='FolderOpen'
                                style={
                                    {marginRight: 5}
                                }/>
                            <span> Reopen survey</span>
                        </Menu.Item>
                    } </Menu>
                );
                return (
                    <>
                        <Dropdown overlay={menu}>
                            <a>Actions</a>
                        </Dropdown>
                    </>
                )
            }
        }
    ];
    

    const data = records.filter(
        (record) => filterTerm?.trim().length === 0 ||
            record.passcode?.toLowerCase().includes(filterTerm.toLowerCase()) ||
            record.tag?.toLowerCase().includes(filterTerm.toLowerCase()) ||
            record.recipientEmailAddresses?.toLowerCase().includes(filterTerm.toLowerCase())
    );
   
    return (
        <>
            <Modal visible={isVisible}
                title="Proceed "
                closable={false}
                width={800}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={
                    [
                        <Button key="back"
                            onClick={handleCancel}>
                            Cancel
                        </Button>,
                        <Button key="submit" type="primary" iconName="FolderOpen"
                            loading={isloading}
                            onClick={handleOk}>
                            Proceed
                        </Button>
                    ]
            }>
                <p>The user has marked this response as complete. Reopening the response will allow the user to modify their answers and resubmit. This in turn may cause outputs to be regenerated such as reports etc.</p>
                <p>Are you sure you wish to proceed?</p>
                {
                hasError && <Alert type="error" message="There was an issue reopening the response, please try again." className="mb-3"/>}
            </Modal>
            { !isLoading &&
            <div className='controlsWrapper'>
            <div className='controls mb-0'>
            {displaySwitch &&
                <>
                Show anonymous responses 
                    <Switch checkedChildren="Yes" defaultChecked unCheckedChildren="No" onChange={filterAnonymousResponses} />
                </>
            } 
           
            </div>
                <span className='extractButtons'>
                 <Button  type='link' className='custom-link-button' iconName='DownloadDocument'
                    onClick={handleExportjson}>
                    Export all to JSON
                </Button>
                <Button type='link' className='custom-link-button'  iconName='DownloadDocument'
                    onClick={handleExportcsv}>
                    Export all to CSV
                </Button>
                </span>
                </div>
                } 
            <KitchensinkTable columns={columns}
                loading={isLoading}
                dataSource={data}
                rowKey='id'
                {...props}/>
            
                              
        </>
    );
}
