import { Row, Col, Card } from "@bdo/kitchensink";
import React, { useState, useEffect } from "react";
import { apiRoutes, buildApiRoute } from "../../config/apiRoutes";
import { useLogging } from "../../hooks/useLogging";
import { useCaptureApi } from "../../hooks/useCaptureApi";
import * as Shared from "../Shared";
import { globalPortalActions, captureManageTabs } from "../../config/enum";
import { generalMessage } from "../../config/errorMessages";
import { useGlobalPortalAssigment } from "../../hooks/useGlobalPortalAssigment";
import AssignmentForm from "./AssignmentForm";
import { useParams } from "react-router-dom";

export default function Update({ view, setView }) {
  const { captureId, assignmentId } = useParams();

  const states = {
    error: "Error",
    notFound: "NotFound",
    loading: "Loading",
    accessDenied: "AccessDenied",
    loaded: "Loaded",
  };

  const [componentState, setComponentState] = useState(states.loading);
  const { apiGet } = useCaptureApi();
  const [errorMessage, setErrorMessage] = useState("");
  const [metaData, setMetaData] = useState({});
  const [assignment, setAssignment] = useState({});
  const logging = useLogging();
  const { Update, View } = useGlobalPortalAssigment();
  const currentAssignmentId = assignmentId !== undefined ? assignmentId : view.record.id;

  async function onSave(values) {
    try {
      setErrorMessage(null);
      var payload = {
        id: assignment.id,
        hasMetaData: metaData.hasMetaData,
        metaData: null,
        passcode: assignment.passcode,
        ...values,
      };

      const result = await Update(payload);

      if (result && result.status === 200) {
        setView({ tab: captureManageTabs.globalPortalActions, action: globalPortalActions.List });
        return;
      } else if (result && result.status === 400) {
        setErrorMessage(result.data);
      } else {
        setErrorMessage(generalMessage.Default);
        logging.error("Failed to update the assignment, non success status code", { result: result });
      }
    } catch (ex) {
      setErrorMessage(generalMessage.Default);
      logging.error("Failed to update the assignment, axios exception", { exception: ex });
    }

    return false;
  }

  useEffect(() => {
    async function fetchData() {
      try {
        setComponentState(states.loading);

        const resultView = await View(currentAssignmentId);

        if (resultView && resultView.status === 200) {
          setAssignment(resultView.data);
        } else if (resultView && resultView.status === 404) {
          setComponentState(states.notFound);
          return;
        } else if (resultView && (resultView.status === 401 || resultView.status === 403)) {
          setComponentState(states.accessDenied);
          return;
        } else {
          setComponentState(states.error);
          logging.error("Unable to load assignment form, non success status code.", { assignmentId: currentAssignmentId, result: resultView });
          return;
        }

        const metaDataResult = await apiGet(buildApiRoute(apiRoutes.metaData.assignment, { captureId: captureId, assignmentId: currentAssignmentId }));

        if (metaDataResult && metaDataResult.status === 200) {
          setMetaData(metaDataResult.data);
          setComponentState(states.loaded);
          return;
        }
        if (metaDataResult && metaDataResult.status === 404) {
          setComponentState(states.notFound);
          return;
        }
        if (metaDataResult && (metaDataResult.status === 401 || metaDataResult.status === 403)) {
          setComponentState(states.accessDenied);
          return;
        }

        setComponentState(states.error);
        logging.error("Unable to load metadata for assignment form, non success status code.", { captureId: captureId, result: metaDataResult });
      } catch (ex) {
        setComponentState(states.error);
        logging.error("Unable to load metadata for assignment form, axios exception.", { assignmentId: currentAssignmentId, exception: ex });
      }
    }
    fetchData();
  }, [currentAssignmentId]);

  const renderState = {
    [states.loading]: () => {
      return <Shared.Loading />;
    },
    [states.error]: () => {
      return <Shared.Error />;
    },
    [states.notFound]: () => {
      return <Shared.NotFound />;
    },
    [states.accessDenied]: () => {
      return <Shared.AccessDenied />;
    },
    [states.loaded]: () => {
      return (
        <div className="content" style={{ paddingTop: "30px" }}>
          <Row>
            <Col span={24}>
              <Card title="Update Assignment">
                <AssignmentForm
                  errorMessage={errorMessage}
                  onSave={onSave}
                  hasMetaData={metaData.hasMetaData}
                  templateSchema={metaData.template}
                  templateData={metaData.data}
                  assignment={assignment}
                  setView={setView}
                  editMode={true}
                />
              </Card>
            </Col>
          </Row>
        </div>
      );
    },
  };

  // Render
  return renderState[componentState]();
}
