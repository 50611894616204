import React, {useState} from 'react'
import { apiRoutes, buildApiRoute } from "../../config/apiRoutes";
import { useCaptureApi } from "../../hooks/useCaptureApi";
import { ByteConvert } from '../../utilities/ByteConversion';
import { FileDownloadFromByteArray } from '../../utilities/FileDownloadFromByteArray';
import { useLogging } from "../../hooks/useLogging";
import { useParams } from "react-router-dom";

function FileDownload(props) {
  const { attachedFiles } = props;
  const { apiGet } = useCaptureApi();
  const logging = useLogging();
  const { captureId } = useParams();

  attachedFiles.value.forEach(function(element){
    element.status= "idle";
    element.progress = 0
  }
);
const fileItems = Array.from(attachedFiles.value);
const [files, setFiles] = useState(fileItems.reverse());

async function downloadFile(file) { 

    const route= buildApiRoute(apiRoutes.responses.downloadFile, { externalCaptureId: captureId , fileName: file.Id});
    setFiles(prevFiles => prevFiles.map(prevFile => {
      if(prevFile.FileName === file.FileName)
      {
        return {...prevFile, status: 'downloading'}
      }
      return prevFile;
    }))
    const result = await apiGet(route,null, true);
    if (result && result.status === 200) {
      FileDownloadFromByteArray(result.data,   file.FileName, file.Type);
      setFiles(prevFiles => prevFiles.map(prevFile => {
        if(prevFile.FileName === file.FileName)
        {
          return {...prevFile, status: 'downloaded', progress: 100}
        }
        return prevFile;
      }))         
    }
    else
    {
      logging.error("Failed to download file, non success status code", { result: result });
      setFiles(prevFiles => prevFiles.map(prevFile => {
        if(prevFile.FileName === file.FileName)
        {
          return {...prevFile, status: 'error', progress: 0}
        }
        return prevFile;
      }))
    }  
}   
    
  return (
        <div className='fileDownloadContainer'>
        <ul > 
          {files.map(file => <li  key={file.FileName}>
            <div className="fileinfo">
              <label>{file.FileName}</label>
              <label className='fileinfo-sub'>{ByteConvert(file.Size)}</label>               
            </div>
            {file.status === 'downloading' && <label className="fileinfo-status"> Downloading...</label>}
            {file.status === 'downloaded' && <label className="fileinfo-status"> Downloaded</label>}
            {file.status === 'idle' && <div className="fixed download-icon" onClick={() => downloadFile(file)}></div> }  
            {file.status === 'error' && <label className="fileinfo-status-Failure"> error in downloading file</label>}          
          </li>)}
        </ul>
        </div>
  );    
  }
  
  export default FileDownload;