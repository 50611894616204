import { apiRoutes, buildApiRoute } from "../config/apiRoutes";
import { useCaptureApi } from "./useCaptureApi";

export function useGlobalPortalAssigment() {
  const { apiGet, apiPost } = useCaptureApi();

  const memberFirmId = process.env.REACT_APP_MF_ID;

  async function GetAssignmentsForCapture(captureId) {
    return await apiGet(buildApiRoute(apiRoutes.globalPortal.list, { captureId: captureId, memberFirmId: memberFirmId }));
  }

  async function Reopen(assignmentId) {
    return await apiGet(buildApiRoute(apiRoutes.globalPortal.reopen, { assignmentId: assignmentId }));
  }

  async function Create(body) {
    return await apiPost(buildApiRoute(apiRoutes.globalPortal.create), body);
  }

  async function Update(body) {
    return await apiPost(buildApiRoute(apiRoutes.globalPortal.update), body);
  }

  async function LoadAssignment(memberFirmId, clientId, projectId, assignmentId) {
    return await apiGet(buildApiRoute(apiRoutes.globalPortal.load, { memberFirmId: memberFirmId, clientId: clientId, projectId: projectId, assignmentId: assignmentId }));
  }
  async function View(assignmentId) {
    return await apiGet(buildApiRoute(apiRoutes.globalPortal.view, { assignmentId: assignmentId }));
  }

  return { GetAssignmentsForCapture, Reopen, Create, Update, LoadAssignment, View };
}
