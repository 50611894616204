import React from 'react';
import { Button } from "@bdo/kitchensink";
import { HomeFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import styles from '../error/ErrorPage.module.scss';

export default function Error({ title = "Error", message = "There was an error processing your request, please try again.", ...props }) {

    const navigate = useNavigate();

    return (
        <div className={styles.errorPage}>
            <div className={styles.errorContainer}>
                <div className={styles.warningIcon}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 30.52 26.69"
                        fill="#98002e"
                    >
                        <path d="M29.69,26.64H.88a.89.89,0,0,1-.76-.44.85.85,0,0,1,0-.87L14.52.38A.91.91,0,0,1,15.79.17,1,1,0,0,1,16,.38l14.41,25a.88.88,0,0,1-.76,1.31ZM2.39,24.89H28.17L15.28,2.56Zm14.39-8V7.41h-3V19Zm-3,4.24v2.3h3V19Z" />
                    </svg>
                </div>
                <h3>{title} - {message}</h3>
                <p>Please try again, if you continue to experience issues then please contact <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>Capture support</a>.</p>
                <p>
                    <Button
                        type="primary"
                        icon={<HomeFilled />}
                        onClick={() => {
                            navigate(document.getElementsByTagName('base')[0].getAttribute('href'));
                        }}
                    >
                        RETURN HOME
                    </Button>
                </p>
                <p className={styles.appVersion}>CBO v{process.env.REACT_APP_VERSION}</p>
            </div>
        </div>
    );
}
