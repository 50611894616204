import { Navigate, Route, Routes } from "react-router-dom";
import * as Template from "../Template";
import * as Capture from "../Capture";
import * as Approval from "../Approval";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="template">
        <Route path="list" element={<Template.List />} />
        <Route path="builder/:id" element={<Template.Builder />} />
        <Route path="builder" element={<Template.Builder />} />
        <Route path="manage/:templateId" element={<Template.Manage />} />
        <Route index element={<Navigate replace to="list" />} />
      </Route>
      <Route path="capture">
        <Route path="list" element={<Capture.List />} />
        <Route path="create" element={<Capture.Manage />} />
        <Route path="manage/:captureId" element={<Capture.Manage />}>
          <Route path=":tab/:action/:memberFirmId/:clientId/:projectId/:assignmentId" element={<Capture.Manage />} />
          <Route path=":tab/:action/:id" element={<Capture.Manage />} />
          <Route path=":tab/:action" element={<Capture.Manage />} />
        </Route>
        <Route index element={<Navigate replace to="list" />} />
      </Route>
      <Route path="approval">
        <Route path="list" element={<Approval.List />} />
        <Route index element={<Navigate replace to="list" />} />
      </Route>
      <Route path="/" element={<Template.List />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}
