import { useCaptureApi } from "./useCaptureApi";
import { apiRoutes, buildApiRoute } from "../config/apiRoutes";

export function useGlobalPortalData() {
  const memberFirmId = process.env.REACT_APP_MF_ID;

  const { apiGet } = useCaptureApi();

  async function GetClientsForUser() {
    return await apiGet(buildApiRoute(apiRoutes.globalPortalData.GetClientsForUser, { memberFirmId: memberFirmId }));
  }

  async function GetProjectsForClient(clientId) {
    return await apiGet(buildApiRoute(apiRoutes.globalPortalData.GetProjectsForClient, { memberFirmId: memberFirmId, clientId: clientId }));
  }

  async function GetUsersForProject(clientId, projectId) {
    return await apiGet(buildApiRoute(apiRoutes.globalPortalData.GetUsersForProject, { memberFirmId: memberFirmId, clientId: clientId, projectId: projectId }));
  }

  return { GetClientsForUser, GetProjectsForClient, GetUsersForProject };
}
