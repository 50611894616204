// set some default values for properties
export function setDefaultPropertyValues(creator) {
  const propertyDefaults = {
    clearInvisibleValues: "none",
    sendResultOnPageNext: false,
    clearIfInvisible: "none",
    loadingHtml: "",
    completedBeforeHtml: "",
    storeOthersAsComment: true,
  };

  creator.onDesignerSurveyCreated.add(function (sender, options) {
    for (const [key, value] of Object.entries(propertyDefaults)) {
      options.survey.setPropertyValue(key, value);
    }
  });

  creator.onQuestionAdded.add(function (sender, options) {
    for (const [key, value] of Object.entries(propertyDefaults)) {
      options.question.setPropertyValue(key, value);
    }
  });
}
