export const appRoutes = {
  capture: {
    list: "/capture/list",
    //view: '/capture/view/:id',
    //embedCode: '/capture/embed-code/:id',
    create: "/capture/create",
    //edit: '/capture/update/:id',
    manage: "/capture/manage/:captureId",
  },
  passcode: {
    list: "/capture/manage/:captureId/passcode/list",
    view: "/capture/manage/:captureId/passcode/view/:passcodeId",
    create: "/capture/manage/:captureId/passcode/create",
    update: "/capture/manage/:captureId/passcode/update/:passcodeId",
  },
  globalPortal: {
    list: "/capture/manage/:captureId/gp/list/:memberFirmId",
    view: "/capture/manage/:captureId/gp/view/:memberFirmId/:clientId/:projectId/:assignmentId",
    create: "/capture/manage/:captureId/gp/create",
    update: "/capture/manage/:captureId/gp/update/:memberFirmId/:clientId/:projectId/:assignmentId",
  },
  response: {
    list: "/capture/manage/:captureId/response/list",
    view: "/capture/manage/:captureId/response/view/:responseId",
    passcode: "/capture/manage/:captureId/response/passcode/:passcodeId",
  },
  template: {
    list: "/template/list",
    view: "/template/builder/:id",
    new: "/template/builder",
    manage: "/template/manage/:templateId",
  },
  approval: {
    list: "/approval/list",
  },
};

export function buildAppRoute(route, tokens, backUrl = false) {
  let url = `${route}`;

  if (backUrl) {
    url = `${url}?backUrl=${window.location.pathname}`;
  }

  if (tokens && typeof tokens === "object") {
    for (const [key, value] of Object.entries(tokens)) {
      url = url.replace(new RegExp(`:${key}`, "gi"), value);
    }
  }

  return url;
}
