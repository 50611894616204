import { createElement } from "react";
import { ElementFactory, Question, Serializer, SvgRegistry } from "survey-core";
import { SurveyQuestionElementBase, ReactQuestionFactory } from "survey-react-ui";
import { PropertyGridEditorCollection } from "survey-creator-react";
import { localization } from "survey-creator-core";
import { ReactComponent as FilePickerIcon } from "../../assets/filePicker.svg";
import ReactDOMServer from "react-dom/server";
import FilePondUpload from './FilePondUpload';

const CUSTOM_TYPE = "file-upload";

// A model for the new question type
export class QuestionFileUploadModel extends Question {
  getType() {
    return CUSTOM_TYPE;
  }  
}

// Register `QuestionFileUploadModel` as a model for the `file-upload` type
export function registerFileUpload() {
    ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
      return new QuestionFileUploadModel(name);
    });
  }

 // Specify display names for the question type and its properties 
const locale = localization.getLocale("");
locale.qt[CUSTOM_TYPE] = "File Upload";

// Register an SVG icon for the question type
const svg = ReactDOMServer.renderToString(<FilePickerIcon />);
SvgRegistry.registerIconFromSvg(CUSTOM_TYPE, svg);

// Add question type metadata for further serialization into JSON
Serializer.addClass(
  CUSTOM_TYPE,
  [{
    name: "fileUpload",
    category: "general",
    visibleIndex: 2 // After the Name and Title
  }],
  function () {
    return new QuestionFileUploadModel("");
  },
  "question"
);

// A class that renders questions of the new type in the UI
export class SurveyQuestionFileUpload extends SurveyQuestionElementBase {
  
  constructor(props) {
    super(props);
    this.state = { files:[],value: this.question.value };
  }
  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }

  // Support the read-only and design modes
  get style() {
    return this.question.getPropertyValue("readOnly") || this.question.isDesignMode ? { pointerEvents: "none" } : undefined;
  }
  
  renderFileUpload(type) {    
    return(
        <FilePondUpload></FilePondUpload>
    ) 
  }

  renderElement() {
    return (
      <div style={this.style}>
        {this.renderFileUpload(this.type)}
      </div>
    );
  }
}

// Register `SurveyQuestionFileUpload` as a class that renders `color-picker` questions 
ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return createElement(SurveyQuestionFileUpload, props);
});

// Register the `color-picker` as an editor for properties of the `color` type in the Survey Creator's Property Grid 
PropertyGridEditorCollection.register({
  fit: function (prop) {
    return prop.type === "fileUpload";
  },
  getJSON: function () {
    return {
      type: CUSTOM_TYPE      
    };
  }
});