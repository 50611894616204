import { SurveyCreator } from "survey-creator-react";
import { hidePropertyGridProperties } from "./creator/hide-property-grid-properties";
import { setDefaultPropertyValues } from "./creator/set-default-property-values";
import { configureToolbox } from "./creator/configure-toolbox";
import { addMarkdownSupport } from "./creator/add-markdown-support";

export function configureCreator(creator) {
    configureToolbox(creator);
    hidePropertyGridProperties(creator);
    setDefaultPropertyValues(creator);
    addMarkdownSupport(creator);
}