import { Row, Col, Card } from '@bdo/kitchensink';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { apiRoutes, buildApiRoute } from '../../config/apiRoutes';
import { useLogging } from '../../hooks/useLogging';
import { useCaptureApi } from '../../hooks/useCaptureApi';
import PasscodeForm from './PasscodeForm';
import * as Shared from '../Shared';
import { captureManageTabs, passcodeActions } from '../../config/enum';
import { generalMessage } from '../../config/errorMessages';

export default function Create({ setView }) {

    const states = {
        error: 'Error',
        notFound: 'NotFound',
        loading: 'Loading',
        accessDenied: 'AccessDenied',
        loaded: 'Loaded'
    }

    const [componentState, setComponentState] = useState(states.loading);
    const { apiPost, apiGet } = useCaptureApi();
    const [errorMessage, setErrorMessage] = useState('');
    const [metaData, setMetaData] = useState({});
    const [passcode, setPasscode] = useState({});
    const { captureId } = useParams();
    const logging = useLogging();

    async function onSave(values) {
        try {
            setErrorMessage(null);

            var payload = {
                captureId: captureId,
                hasMetaData: metaData.hasMetaData,
                metaData: null,
                ...values
            }
            const result = payload.multipleCreation ? await apiPost(buildApiRoute(apiRoutes.passcodes.bulkCreate), payload) : await apiPost(buildApiRoute(apiRoutes.passcodes.create), payload);

            if (result && result.status === 200) {
                setView({
                    tab: captureManageTabs.Passcodes,
                    action: passcodeActions.List
                });
                return true;
            } else if (result && result.status === 400) {
                setErrorMessage(result.data);
            } else {
                setErrorMessage(generalMessage.Default);
                logging.error("Failed to create passcode, non success status code", { result: result });
            }
        }
        catch (ex) {
            setErrorMessage(generalMessage.Default);
            logging.error("Failed to create passcode, axios exception", { exception: ex });
        }

        return false;
    }

    useEffect(() => {
        async function fetchData() {
            try {
                setComponentState(states.loading);

                const result = await apiGet(buildApiRoute(apiRoutes.metaData.capture, { captureId: captureId }));

                if (result && result.status === 200) {
                    setMetaData(result.data);
                    setComponentState(states.loaded);
                    return;
                }
                if (result && result.status === 404) {
                    setComponentState(states.notFound);
                    return;
                }
                if (result && (result.status === 401 || result.status === 403)) {
                    setComponentState(states.accessDenied);
                    return;
                }

                setComponentState(states.error);
                logging.error("Unable to load metadata for passcode form, non success status code.", { captureId: captureId, result: result });
            }
            catch (ex) {
                setComponentState(states.error);
                logging.error("Unable to load metadata for passcode form, axios exception.", { captureId: captureId, exception: ex });
            }
        }
        fetchData();
    }, [captureId]);

    const renderState = {
        [states.loading]: () => {
            return (<Shared.Loading />);
        },
        [states.error]: () => {
            return (<Shared.Error />);
        },
        [states.notFound]: () => {
            return (<Shared.NotFound />);
        },
        [states.accessDenied]: () => {
            return (<Shared.AccessDenied />);
        },
        [states.loaded]: () => {
            return (
                <div className='content' style={{ paddingTop: '30px' }}>
                    <Row>
                        <Col span={24}>
                            <Card title="Create Passcode">
                                <PasscodeForm
                                    errorMessage={errorMessage}
                                    onSave={onSave}
                                    hasMetaData={metaData.hasMetaData}
                                    templateSchema={metaData.template}
                                    templateData={metaData.data}
                                    passcode={passcode}
                                    setView={setView}                                    
                                    CreateMultiplePasscodeEnable={true}
                                />
                            </Card>
                        </Col>
                    </Row>
                </div>
            );
        },
    }

    // Render
    return (renderState[componentState]());
}