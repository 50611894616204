import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APP_INSIGHTS_CONNECTIONSTRING,
        enableAutoRouteTracking: true, // for react router v6
        extensions: [reactPlugin]        
    }
});

appInsights.loadAppInsights();

export { reactPlugin as appInsightsReactPlugin, appInsights as appInsightsInstance}