export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const apiRoutes = {
  templates: {
    list: "/template/list",
    published: "/template/list/published",
    view: "/template/view/:id",
    create: "/template/create",
    update: "/template/update",
    duplicate:"/template/duplicate/:id",
  },
  captures: {
    list: "/capture/list",
    create: "/capture/create",
    update: "/capture/update",
    view: "/capture/view/:id",
  },
  passcodes: {
    list: "/passcode/list/:externalCaptureId",
    create: "/passcode/create",
    update: "/passcode/update",
    view: "/passcode/view/:id",
    reopen: "/passcode/reopen/:id",    
    bulkCreate: "/passcode/bulk-create",
  },
  externalCapture: {
    list: "/externalcapture/list/:externalCaptureId",
  },
  globalPortal: {
    list: "/gp-assignment/list/capture/:captureId/mf/:memberFirmId",
    create: "/gp-assignment/create",
    update: "/gp-assignment/update",
    reopen: "/gp-assignment/reopen/:assignmentId",
    load: "/gp/memberfirm/:memberFirmId/client/:clientId/project/:projectId/load/:assignmentId",
    view: "/gp-assignment/view/:assignmentId",
  },
  globalPortalData: {
    GetClientsForUser: "/gp/memberfirm/:memberFirmId/user/clients",
    GetProjectsForClient: "/gp/memberfirm/:memberFirmId/user/client/:clientId/projects",
    GetUsersForProject: "/gp/memberfirm/:memberFirmId/client/:clientId/project/:projectId/users",
  },
  metaData: {
    capture: "/meta-data/capture/:captureId",
    passcode: "/meta-data/capture/:captureId/passcode/:passcodeId",
    assignment: "/meta-data/capture/:captureId/assignment/:assignmentId",
  },
  responses: {
    list: "/response/list/:externalCaptureId",
    viewer: "/response/viewer-data/:id",
    passcode: "/response/passcode/:passcodeId",
    pdf: "/pdf-info/:id",
    downloadFile:"/response/downloadFile/:externalCaptureId/:fileName"
  },
  templateSettings: {
    get: "/TemplateSettings/get/:templateId",
    update: "/TemplateSettings/update",
  },
  MSGraph: {
    getGroups: "/MSGraph/list/groups/:startWithValue",
    getGroupsAndUsers: "/MSGraph/list/groupsandusers/:startWithValue",
    getcaptureapprovers:"/MSGraph/list/captureapprovers",
  },
  approval: {
    list: "/templateApproval/list",
    update: "/templateApproval/update",
    create: "/templateApproval/create",
  },
  responseExtractExternal: {
    response: "/response-extract-external/:responseId",
    list: "/response-extract-external/list/:captureId/:includeAnonymous"
  },
  responseExtractGlobal: {
    response: "/response-extract-global/:responseId/mf/:memberfirmId",
    list: "/response-extract-global/list/:captureId/mf/:memberfirmId"
  }
};

export function buildApiRoute(route, tokens) {
  let url = `${apiBaseUrl}${route}`;

  if (tokens && typeof tokens === "object") {
    for (const [key, value] of Object.entries(tokens)) {
      url = url.replace(new RegExp(`:${key}`, "gi"), value);
    }
  }

  return url;
}
