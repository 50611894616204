import { Alert, Button, Form, Input, Card, Select, Switch } from "@bdo/kitchensink";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiRoutes, buildApiRoute } from "../../config/apiRoutes";
import { appRoutes, buildAppRoute } from "../../config/appRoutes";
import { useCaptureApi } from "../../hooks/useCaptureApi";
import { useLogging } from "../../hooks/useLogging";
import BackButton from "../misc/BackButton";

export default function CaptureForm({ title, isUpdating, disableTabs, capture, setCapture }) {
  const { apiGet, apiPost } = useCaptureApi();
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const logging = useLogging();
  const Option = Select.Option;
  const [isSaving, setIsSaving] = useState(false);
  const [message, setMessage] = useState(null);

  const validateMessages = {
    required: "${label} is required.",
    max: "${label} is max is ${value}.",
  };
  const [templateOptions, setTemplateOptions] = useState({});

  function showError() {
    setMessage({
      type: "error",
      text: "There was an issue communicating with the server, please try again.",
    });
  }

  function showSuccess() {
    setMessage({
      type: "success",
      text: "Your changes were saved.",
    });
  }

  function clearMessage() {
    setMessage(null);
  }

  useEffect(() => {
    async function fetchData() {
      if (!isUpdating) {
        try {
          setTemplates([]);
          setIsLoading(true);
          setHasError(false);

          const result = await apiGet(buildApiRoute(apiRoutes.templates.published));

          if (result && result.status === 200) {
            setTemplates(result.data);
            setTemplateOptions(
              result.data.map((template) => {
                return (
                  <Option key={template.templateId} value={template.templateId}>
                    {template.name}
                  </Option>
                );
              })
            );
          } else {
            showError();
            logging.error("Failed to list templates, non success status code", { result: result });
          }
        } catch (ex) {
          showError();
          logging.error("Failed to list templates, axios exception", { exception: ex });
        }
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);

  async function onFinish(values) {
    try {
      setIsSaving(true);
      clearMessage();

      var payload = values;
      let result;
      if (isUpdating) {
        payload.id = capture.id;
        result = await apiPost(buildApiRoute(apiRoutes.captures.update), payload);
      } else {
        if (payload.allowPasscodeResponses === undefined) {
          payload.allowPasscodeResponses = true;
        }
        if (payload.allowAnonymousResponses === undefined) {
          payload.allowAnonymousResponses = true;
        }
        if (payload.allowGlobalPortalResponses === undefined) {
          payload.allowGlobalPortalResponses = true;
        }
        let selectedTemplate = templates.find((template) => template.templateId === values.templateId);
        payload.templateVersion = selectedTemplate?.version;
        payload.templateName = selectedTemplate?.name;
        result = await apiPost(buildApiRoute(apiRoutes.captures.create), payload);
      }

      if (result && result.status === 200) {
        setIsSaving(false);
        if (!isUpdating) {
          navigate(buildAppRoute(appRoutes.capture.manage, { captureName: result.data.title, captureId: result.data.id }));
          return;
        }

        disableTabs(!(payload.allowPasscodeResponses || payload.allowAnonymousResponses), !payload.allowGlobalPortalResponses);

        showSuccess();
        setCapture(result.data);
        return;
      } else if (result && result.status === 400) {
        setIsSaving(false);
        setMessage({
          type: "error",
          text: result.data,
        });
        return;
      }
      setIsSaving(false);
      showError();
      logging.error("Failed to update capture, non success status code", { result: result });
      return;
    } catch (ex) {
      setIsSaving(false);
      showError();
      logging.error("Failed to update capture, axios exception", { exception: ex });
    }
  }

  return (
    <Card title={title} className="">
      {message && (
        <Alert
          type={message.type}
          message={message.text}
          style={{ marginBottom: "10px" }}
          closable
          afterClose={() => {
            clearMessage();
          }}
        />
      )}
      <Form layout="vertical" name="external-capture" onFinish={onFinish} validateMessages={validateMessages} initialValues={isUpdating ? capture : {}}>
        {isUpdating ? (
          <Form.Item name={["templateName"]} label="Template name">
            <Input className="ant-form-text" disabled={true}></Input>
          </Form.Item>
        ) : (
          <Form.Item name={["templateId"]} label="Template" rules={[{ required: true }]}>
            <Select
              showSearch
              placeholder="Select a template"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              loading={isLoading}
            >
              {templates.map((template) => (
                <Option key={template.templateId} value={template.templateId}>
                  {template.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item name={["title"]} label="Title" rules={[{ required: true }, { max: 200 }]}>
          <Input placeholder="Capture title" />
        </Form.Item>
        <Form.Item name={["description"]} label="Description" rules={[{ required: true }, { max: 5000 }]}>
          <Input.TextArea placeholder="A short description" style={{ height: 120 }} />
        </Form.Item>
        <Form.Item name={["embedUrl"]} label="Embed URL" rules={[{ max: 5000 }]}>
          <Input placeholder="URL where the external capture is embeded" />
        </Form.Item>
        <div className="ant-row ant-form-item">
          Allow Global Portal responses
          <Form.Item name={["allowGlobalPortalResponses"]} noStyle="true" labelAlign="right" label="Allow Global Portal responses" valuePropName="checked">
            <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked style={{ position: "absolute", right: "30px" }} />
          </Form.Item>
        </div>
        <div className="ant-row ant-form-item">
          Allow passcode responses
          <Form.Item name={["allowPasscodeResponses"]} noStyle="true" labelAlign="right" label="Allow passcode responses" valuePropName="checked">
            <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked style={{ position: "absolute", right: "30px" }} />
          </Form.Item>
        </div>
        <div className="ant-row ant-form-item">
          Allow anonymous responses
          <Form.Item name={["allowAnonymousResponses"]} noStyle="true" labelAlign="right" label="Allow anonymous responses" valuePropName="checked">
            <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked style={{ position: "absolute", right: "30px" }} />
          </Form.Item>
        </div>
        <Form.Item>
          <div className="d-flex flex-row justify-content-between">
            <BackButton type="default" url={buildAppRoute(appRoutes.capture.list)}>
              Cancel
            </BackButton>
            <Button className="ms-3" type="success" iconName="Save" htmlType="submit" loading={isSaving}>
              {isUpdating ? "Submit" : "Save"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Card>
  );
}
