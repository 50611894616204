import React, { useState, useEffect } from "react";
import * as SurveyJS from "survey-react";
import "survey-core/survey.i18n";
import "survey-react/defaultV2.css";
import { StylesManager } from "survey-react";
import "./surveyJS.css";
import MarkdownIt from "markdown-it";
import attrs from "markdown-it-attrs";
import FileDownload from "../../fileUpload/FileDownload";


StylesManager.applyTheme("defaultV2");

// register custom fiel upload question
SurveyJS.ComponentCollection.Instance.add({
  name: "file-upload",
  title: "file-upload"
},
);

// register custom file upload widget to survey
SurveyJS.CustomWidgetCollection.Instance.addCustomWidget({
  name: 'file-upload',
  isFit: question => {
    return question.getType() == "file-upload";
  },
  render: question => {      
   if(question.value !== undefined)
    return <FileDownload  attachedFiles={ question } />
    else
    return <lable>No files are attached.</lable>  
  }
})

function updateStringComponents(_, options) {
  var questionVal = options.question.value;
  if (isValidUrl(questionVal)) {
    var div = document.createElement("div");

    div.className = "sd_customLink";
    div.innerHTML = "<a href='" + questionVal + "' target='new'>Click here</a>";
    options.htmlElement.appendChild(div);
  }
}

const isValidUrl = urlString => {
  var inputElement = document.createElement('input');
  inputElement.type = 'url';
  inputElement.value = urlString;

  if (!inputElement.checkValidity()) {
    return false;
  } else {
    return true;
  }
}

export function KitchensinkSurvey({
  template,
  allowSaveProgress,
  onSaveProgress,
  mode,
  onComplete,
  data,
  ...props
}) {
  const [surveyModel] = useState(new SurveyJS.SurveyModel(template));
  const markdownConverter = new MarkdownIt();
  markdownConverter.use(attrs, {
    allowedAttributes: ["target"],
  });

  useEffect(() => {
    if (!!data) {
      // fix #105058
      surveyModel.data = data;
      surveyModel.currentPageNo = 0; // force surveyJS to recalculate expressions see: https://surveyjs.io/form-library/documentation/api-reference/survey-data-model#data
    }
  }, []);

  // language selection
  const [language] = useState("en");

  surveyModel.locale = language;
  surveyModel.onAfterRenderQuestion.add(updateStringComponents);
  if (!!data) {
    surveyModel.data = data;
  }
  function onTextMarkdown(survey, options) {
    if (options.name !== "title") {
      options.html = markdownConverter.render(options.text);
    }
  }

  return (
    <>
      <SurveyJS.Survey
        model={surveyModel}
        mode={mode}
        onTextMarkdown={onTextMarkdown}
        {...props}
      />
    </>
  );
}
