import React, { useEffect, useState } from "react";
import { Survey } from "survey-react";
import "survey-core/survey.i18n";
import "survey-react/defaultV2.css";
import { StylesManager, Model } from "survey-core";
import * as Shared from "../../Shared";
import "./surveyJS.css";
import MarkdownIt from "markdown-it";
import attrs from "markdown-it-attrs";

export default function MetaDataSurvey({
  value = {},
  onChange,
  templateSchema,
  templateData,
  onAfterRenderSurvey,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [surveyModel, setSurveyModel] = useState({});
  const markdownConverter = new MarkdownIt();
  markdownConverter.use(attrs, {
    allowedAttributes: ["target"],
  });

  useEffect(() => {
    setIsLoading(true);

    StylesManager.applyTheme("defaultV2");

    // fix #102900
    templateSchema.widthMode = "responsive";
    delete templateSchema.width;
    delete templateSchema.showPreviewBeforeComplete;

    const survey = new Model({
      ...templateSchema,
      ...{ focusFirstQuestionAutomatic: false },
    });

    // survey.showNavigationButtons = false;

    if (!!templateData) {
      // fix #105058
      survey.data = templateData;
      survey.currentPageNo = 0; // force surveyJS to recalculate expressions see: https://surveyjs.io/form-library/documentation/api-reference/survey-data-model#data

      onChange(survey.data);
    }

    setSurveyModel(survey);

    setIsLoading(false);
  }, []);

  function hideCompleteButton() {
    const completeButton = document.getElementById("sv-nav-complete");
    if (completeButton) {
      completeButton.remove();
    }
  }

  function onValueChanged(sender) {
    value = sender.data;
    onChange(value);
  }

  function onTextMarkdown(survey, options) {
    if (options.name !== "title") {
      options.html = markdownConverter.render(options.text);
    }
  }

  if (isLoading) {
    return <Shared.Loading />;
  }

  return (
    <Survey
      model={surveyModel}
      onValueChanged={onValueChanged}
      onDynamicPanelItemValueChanged={onValueChanged}
      onMatrixCellValueChanged={onValueChanged}
      onAfterRenderPage={hideCompleteButton}
      onAfterRenderSurvey={onAfterRenderSurvey}
      onCurrentPageChanged={(sender, options) => {
        surveyModel.focusFirstQuestion();
      }}
      onTextMarkdown={onTextMarkdown}
      mode="edit"
      {...props}
    />
  );
}
