import { useCaptureApi } from "./useCaptureApi";
import { apiRoutes, buildApiRoute } from "../config/apiRoutes";

export function useResponseExtractExternalData() {

  const { apiGet } = useCaptureApi();

  async function GetResponseExtractForResponseId(responseId) {
    return await apiGet(buildApiRoute(apiRoutes.responseExtractExternal.response, { responseId: responseId }));
  }
  async function GetExtractForCaptureId(captureId, includeAnonymous) {
    return await apiGet(buildApiRoute(apiRoutes.responseExtractExternal.list, { captureId: captureId, includeAnonymous: includeAnonymous  }));
  }
  return { GetResponseExtractForResponseId, GetExtractForCaptureId };
}