import React from "react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { appInsightsInstance as ai } from "../insights/AppInsights";
import { Navigate } from "react-router-dom";
import ErrorPage from "./ErrorPage";
import { ApiError } from "../../errors/ApiError";
import { ApplicationError } from "../../errors/ApplicationError";
import { ErrorTypes } from "../../errors/ErrorTypes";

const startingState = {
  hasError: false,
  redirect: false,
  error: null,
}

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = startingState;
  }

  componentDidCatch(error, errorInfo) {

    this.setState({
      hasError: true,
      redirect: false,
      error: error
    });

    if (error instanceof ApiError) {
      ai.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: {
          errorType: error.errorType,
          errorCode: error.errorCode,
          httpStatusCode: error.httpStatusCode,
          ...error.additionalInfo,
          ...errorInfo
        }
      });
      return;
    }

    if (error instanceof ApplicationError) {
      ai.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: {
          errorType: error.errorType,
          errorCode: error.errorCode,
          ...error.additionalInfo,
          ...errorInfo
        }
      });
      return;
    }

    if (error instanceof Error) {
      ai.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: {
          errorType: ErrorTypes.unexpected,
          ...errorInfo
        }
      });
      return;
    }
  }

  redirectToHome() {
    this.setState({ redirect: true, hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        <ErrorPage error={this.state.error} onClick={() => this.redirectToHome()} />
      );
    }

    if (this.state.redirect) {
      this.setState(startingState);
      return (
        <Navigate to={{ pathname: "/" }} />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;