import { useCaptureApi } from "./useCaptureApi";
import { apiRoutes, buildApiRoute } from "../config/apiRoutes";

export function useResponseExtractGlobalData() {
  const memberFirmId = process.env.REACT_APP_MF_ID;
  const { apiGet } = useCaptureApi();

  async function GetResponseExtractForResponseId(responseId) {
    return await apiGet(buildApiRoute(apiRoutes.responseExtractGlobal.response, { responseId: responseId, memberfirmId: memberFirmId }));
  }
  async function GetExtractForCaptureId(captureId, includeAnonymous) {
    return await apiGet(buildApiRoute(apiRoutes.responseExtractGlobal.list, { captureId: captureId, memberfirmId: memberFirmId}));
  }
  return { GetResponseExtractForResponseId, GetExtractForCaptureId };
}