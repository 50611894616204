import { Tabs, Card } from "@bdo/kitchensink";
import React, { useEffect, useState } from "react";
import * as Capture from "../Capture";
import * as Passcode from "../Passcode";
import * as Shared from "../Shared";
import * as GlobalPortal from "../GlobalPortal";
import { useParams } from "react-router-dom";
import { useCaptureApi } from "../../hooks/useCaptureApi";
import { apiRoutes, buildApiRoute } from "../../config/apiRoutes";
import { appRoutes, buildAppRoute } from "../../config/appRoutes";
import { captureActions, captureManageTabs, passcodeActions, globalPortalActions } from "../../config/enum";
import { useLogging } from "../../hooks/useLogging";

const TabPane = Tabs.TabPane;

export default function Manage() {
  const states = {
    error: "Error",
    notFound: "NotFound",
    loading: "Loading",
    accessDenied: "AccessDenied",
    loaded: "Loaded",
  };

  const { captureId, tab, action, id, clientId, projectId, assignmentId } = useParams();
  const [view, setView] = useState({
    tab: tab,
    action: action,
    id: id,
    record: undefined,
    captureId: captureId //Added to support update/view tabs for Global Portal captures
  });
  const [componentState, setComponentState] = useState(states.loading);
  const [passcodesDisabled, setPasscodesDisabled] = useState(true);
  const [gpcDisabled, setgpcDisabled] = useState(true);
  const [capture, setCapture] = useState({});
  const { apiGet } = useCaptureApi();
  const logging = useLogging();
  const memberFirmId = process.env.REACT_APP_MF_ID;

  const onTabChanged = function (activeKey) {
    //Setting the view object to a default action.
    let result = { ...view, ...{ tab: activeKey, action: captureActions.Manage } };
    setView(result);
  };

  function disableTabs(passcodes, gpc) {
    setPasscodesDisabled(passcodes);
    setgpcDisabled(gpc);
  }

  const renderState = {
    [states.loading]: () => {
      return <Shared.Loading />;
    },
    [states.error]: () => {
      return <Shared.Error />;
    },
    [states.notFound]: () => {
      return <Shared.NotFound />;
    },
    [states.accessDenied]: () => {
      return <Shared.AccessDenied />;
    },
    [states.loaded]: () => {
      let panes = [
        {
          title: "Global Portal Capture",
          key: captureManageTabs.GlobalPortal,
          content: (
            <Card>
              <GlobalPortal.Tab capture={capture} view={view} setView={setView} />
            </Card>
          ),
          disabled: gpcDisabled,
        },
        {
          title: "External Capture",
          key: captureManageTabs.Passcodes,
          content: (
            <Card>
              <Passcode.Tab capture={capture} view={view} setView={setView} />
            </Card>
          ),
          disabled: passcodesDisabled,
        },
        { title: "Settings", key: captureManageTabs.Settings, content: <Capture.Tab capture={capture} view={view} disableTabs={disableTabs} setCapture={setCapture} /> },
      ];

      return (
        <div className="capture-layout">
          <h1>{capture !== null ? capture.title : "Create capture"}</h1>
          <Tabs activeKey={view.tab} onChange={onTabChanged}>
            {panes.map((pane, i) => {
              if (!pane.disabled) {
                return (
                  <TabPane tab={pane.title} key={pane.key} disabled={pane.disabled}>
                    {pane.content}
                  </TabPane>
                );
              }
            })}
          </Tabs>
        </div>
      );
    },
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setCapture({});
        setComponentState(states.loading);

        if (captureId) {
          if (!tab && !action) {
            setView({
              captureId: captureId,
              tab: captureManageTabs.Settings,
              action: captureActions.Manage,
            });
          }

          const result = await apiGet(buildApiRoute(apiRoutes.captures.view, { id: captureId }));

          if (result && result.status === 200) {
            setCapture(result.data);
            if (result.data.allowPasscodeResponses || result.data.allowAnonymousResponses) {
              setPasscodesDisabled(false);
            }
            setgpcDisabled(!result.data.allowGlobalPortalResponses);
            setComponentState(states.loaded);
          }

          if (result && (result.status === 401 || result.status === 403)) {
            setComponentState(states.accessDenied);
          }

          if (result && result.status === 404) {
            setComponentState(states.notFound);
          }

          if (result && ![200, 401, 403, 404].includes(result.status)) {
            setComponentState(states.error);
            logging.error("Failed to load capture, non success status code", { result: result });
          }
        } else {
          // create capture
          setView({
            captureId: captureId,
            tab: captureManageTabs.Settings,
            action: captureActions.Create,
          });
          setPasscodesDisabled(true);
          setComponentState(states.loaded);
        }
      } catch (ex) {
        setComponentState(states.error);
        logging.error("Failed to load capture, axios exception", { exception: ex });
      }
    }
    fetchData();
  }, [captureId]);

  useEffect(() => {
    // eslint-disable-next-line default-case
    switch (view.tab) {
      case captureManageTabs.Settings:
        switch (view.action) {
          case captureActions.Create:
            window.history.pushState(null, null, buildAppRoute(appRoutes.capture.create));
            break;
          case captureActions.Manage:
            window.history.pushState(null, null, buildAppRoute(appRoutes.capture.manage, { captureId: captureId }));
            break;
          default:
            window.history.pushState(null, null, buildAppRoute(appRoutes.capture.manage, { captureId: captureId }));
            break;
        }
        break;
      case captureManageTabs.Passcodes:
        switch (view.action) {
          case passcodeActions.View:
            window.history.pushState(null, null, buildAppRoute(appRoutes.passcode.view, { captureId: captureId, passcodeid: view.id }));
            break;
          case passcodeActions.Create:
            window.history.pushState(null, null, buildAppRoute(appRoutes.passcode.create, { captureId: captureId }));
            break;
          case passcodeActions.Update:
            window.history.pushState(null, null, buildAppRoute(appRoutes.passcode.update, { captureId: captureId, passcodeid: view.id }));
            break;
          case passcodeActions.List:
            window.history.pushState(null, null, buildAppRoute(appRoutes.passcode.list, { captureId: captureId }));
            break;
          default:
            window.history.pushState(null, null, buildAppRoute(appRoutes.passcode.list, { captureId: captureId }));
            break;
        }
        break;
      case captureManageTabs.GlobalPortal:
        switch (view.action) {
          case globalPortalActions.Create:
            window.history.pushState(null, null, buildAppRoute(appRoutes.globalPortal.create, { captureId: captureId }));
            break;
          case globalPortalActions.Update:
            window.history.pushState(
              null,
              null,
              buildAppRoute(appRoutes.globalPortal.update, {
                captureId: captureId,
                memberFirmId: memberFirmId,
                clientId: clientId ? clientId : view.record.clientId,
                projectId: projectId ? projectId : view.record.projectId,
                assignmentId: assignmentId ? assignmentId : view.id,
              })
            );
            break;
          case globalPortalActions.List:
            window.history.pushState(null, null, buildAppRoute(appRoutes.globalPortal.list, { captureId: captureId, memberFirmId: memberFirmId }));
            break;
          case globalPortalActions.View:
            window.history.pushState(
              null,
              null,
              buildAppRoute(appRoutes.globalPortal.view, {
                captureId: captureId,
                memberFirmId: memberFirmId,
                clientId: clientId ? clientId : view.record.clientId,
                projectId: projectId ? projectId : view.record.projectId,
                assignmentId: assignmentId ? assignmentId : view.id,
              })
            );
            break;
          default:
            window.history.pushState(null, null, buildAppRoute(appRoutes.globalPortal.list, { captureId: captureId, memberFirmId: memberFirmId }));
            break;
        }
        break;
    }
  }, [captureId, view.tab, view.action, view.id]);

  // Render
  return renderState[componentState]();
}
