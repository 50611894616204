import React, { useState } from 'react';
import { Layout } from '@bdo/kitchensink';
import HeaderMenu from './HeaderMenu';
import { contentLayouts, ContentLayoutContext } from './ContentLayoutContext';

export default function AppLayout(props) {

    const { Header, Content } = Layout;
    const [contentLayout, setContentLayout] = useState(contentLayouts.default);

    return (
        <Layout className='layout'>
            <Header>
                <HeaderMenu></HeaderMenu>
            </Header>
            <Content>                
                <div className={contentLayout} >
                    <ContentLayoutContext.Provider value={{ setContentLayout }}>
                        {props.children}
                    </ContentLayoutContext.Provider>
                </div>
            </Content>
        </Layout>
    );
}