import { Serializer } from "survey-core";

// fix #82313 - remove datetime input type
export function removeDateTimeType() {
    var prop = Serializer
        .findProperty("text", "inputType");

    const index = prop.choices.indexOf("datetime");
    if (index > -1) {
        prop.choices.splice(index, 1);
    }
}