import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Menu, Row, Col, Dropdown } from '@bdo/kitchensink';
import { useCaptureApi } from '../../hooks/useCaptureApi';
import { apiRoutes, buildApiRoute } from '../../config/apiRoutes';
import { approvalStatus } from '../../config/enum';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';

export default function HeaderMenu() {

    let currentLocation = 'template';

    if (window.location.pathname.includes('template')) {
        currentLocation = 'template';
    }

    if (window.location.pathname.includes('capture') || window.location.pathname.includes('passcode') || window.location.pathname.includes('response')) {
        currentLocation = 'capture';
    }
    if (window.location.pathname.includes('approval')) {
        currentLocation = 'approval';
    }

    const [menuCurrentItem, setMenuCurrentItem] = useState(currentLocation);
    const [canSeeApprovals, setCanSeeApprovals] = useState(false);
    const { apiPost } = useCaptureApi();
    const { instance, accounts } = useMsal();

    useEffect(() => {
        async function fetch() {
            const statusList = [
                approvalStatus.Pending,
                approvalStatus.InReview,
            ];
            const response = await apiPost(buildApiRoute(apiRoutes.approval.list), statusList);

            if (response && response.status === 200) {
                setCanSeeApprovals(true);
            }
        }
        fetch();
    }, [])

    const doLogout = () => {
        instance.logoutRedirect();
    }

    const avatarDropdownItems = (
        <Menu>
            <Menu.Item key={1} className='information' icon={<UserOutlined />}>{accounts[0].name}</Menu.Item>
            <Menu.Divider />
            <Menu.Item key={2} icon={<LogoutOutlined />} danger={true} >
                <a onClick={doLogout}>logout</a>
            </Menu.Item>
        </Menu>
    );

    return (
        <Row justify="space-between" style={{ gridArea: 'header-main' }} wrap={false}>
            <Col flex="1 0">
                <Menu theme='dark' mode='horizontal' selectedKeys={[menuCurrentItem]} >
                    <Menu.Item key='template' >
                        <Link to={'/template'} onClick={() => setMenuCurrentItem('template')}>My templates</Link>
                    </Menu.Item>
                    <Menu.Item key='capture' >
                        <Link to={'/capture'} onClick={() => setMenuCurrentItem('capture')}>My Captures</Link>
                    </Menu.Item>
                    {canSeeApprovals &&
                        <Menu.Item key='approval' >
                            <Link to={'/approval'} onClick={() => setMenuCurrentItem('approval')}>Approval</Link>
                        </Menu.Item>
                    }
                </Menu>
            </Col>
            <Col>
                <Dropdown overlay={avatarDropdownItems} placement="bottomRight" arrow>
                    <Avatar size="small" icon={<UserOutlined />} style={{ marginRight: '10px' }} className='user-dropdown' />
                </Dropdown>
            </Col>
        </Row>
    );
}