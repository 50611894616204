import React, { useEffect, useState } from "react";
import { apiRoutes, buildApiRoute } from "../../config/apiRoutes";
import { useLogging } from "../../hooks/useLogging";
import { useCaptureApi } from "../../hooks/useCaptureApi";
import { KitchensinkSurvey } from "../shared/survey/KitchensinkSurvey";
import { captureManageTabs, responseActions } from "../../config/enum";
import BackButton from "../misc/BackButton";
import * as Shared from "../Shared";

export default function View({ view, setView }) {
  const responseId = view.id;
  const { apiGet } = useCaptureApi();
  const [record, setRecord] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const logging = useLogging();
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        setHasError(false);
        const result = await apiGet(buildApiRoute(apiRoutes.responses.viewer, { id: responseId }));
        if (result && result.status === 200) {
          // start fix #104696
          result.data.templateData.widthMode = "responsive";
          delete result.data.templateData.width;
          delete result.data.templateData.showPreviewBeforeComplete;
          // end fix #104696

          setRecord(result.data);
          setIsLoading(false);
          return;
        }

        if (result && result.status === 404) {
          setNotFound(true);
          setIsLoading(false);
          return;
        }
        setHasError(true);
        logging.error("Failed to fetch the response, non success status code", { result: result });
      } catch (ex) {
        logging.error("Failed to fetch the response, non success status code", { exception: ex });
        setHasError(true);
      }

      setIsLoading(false);
    }
    fetchData();
  }, [responseId]);

  if (isLoading) {
    return <Shared.Loading />;
  }

  if (hasError) {
    return <Shared.Error />;
  }

  if (notFound) {
    return <Shared.NotFound />;
  }

  return (
    <div>
      <KitchensinkSurvey template={record.templateData} data={record.userData} mode="display" />
      <div className="d-flex justify-content-between">
        <BackButton type="danger" onClick={() => setView({ tab: captureManageTabs.Passcodes, action: responseActions.List })}>
          Back
        </BackButton>
      </div>
    </div>
  );
}
