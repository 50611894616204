import { Serializer } from "survey-core";

// add new properties for meta data flags
export function addMetaDataProperties() {    
    const metaDataClassTypes = [
        'question',
        'panel'
    ];

    metaDataClassTypes.forEach(function (classType) {
        Serializer.addProperty(classType, {
            name: 'isMetaData',
            displayName: 'Is meta data',
            type: 'boolean',
            category: 'Meta Data',
            categoryIndex: 1,
            default: null,
        });
    
        Serializer.addProperty(classType, {
            name: 'visibleToEndUser',
            displayName: 'Visible to end user',
            type: 'boolean',
            category: 'Meta Data',
            categoryIndex: 1,
            dependsOn: ['isMetaData'],
            visibleIf: function (obj) {
                return (obj.isMetaData);
            },
            default: null,
        });

        // control visibilty and value of "visible" property depending on meta data property values
        let visibleProperty = Serializer.getProperty(classType, "visible");
        visibleProperty.addDependedProperty('isMetaData');
        visibleProperty.addDependedProperty('visibleToEndUser');
        visibleProperty.visibleIf = function(obj) {
            if (obj.getPropertyValue("isMetaData") === true) {
                if (obj.getPropertyValue("visibleToEndUser") === false) {
                    obj.setPropertyValue("visible", false);
                }
                else {
                    obj.setPropertyValue("visible", true);
                }                
                return false;
            }
            else 
            {
                obj.setPropertyValue("visible", true);
                obj.setPropertyValue("visibleToEndUser", false);
                return true;
            }
        }
    });
}