export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AUTH_TENANT_ID}`,
        redirectUri: `https://${window.location.host}/auth`
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
 export const scopesRequest = {
  scopes: [`${process.env.REACT_APP_APP_API_APP_ID_URI}/.default`],
  redirectUri : `https://${window.location.host}/auth`,
};
