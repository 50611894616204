export function FileDownloadFromByteArray(byteArray, fileName, type )
{    
    var file =new Blob([byteArray], {type: type});
    var link=document.createElement('a');
    link.href=window.URL.createObjectURL(file);
    link.download=fileName;
    link.click();    
}


