import { Button, Input, Table } from '@bdo/kitchensink';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCaptureApi } from '../../hooks/useCaptureApi';
import { apiRoutes, buildApiRoute } from '../../config/apiRoutes';
import { appRoutes, buildAppRoute } from '../../config/appRoutes';
import { useLogging } from '../../hooks/useLogging';
import { approvalStatus } from '../../config/enum';
import { Dropdown, Menu } from 'antd';

export default function List() {
    const { apiPost, apiPut,apiGet } = useCaptureApi();
    const [templates, setTemplates] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [tableLoading, setTableLoading] = useState(true);
    const [search, setSearch] = useState(undefined);
    const navigate = useNavigate();   
    const logging = useLogging();

    useEffect(() => {
        async function fetch() {
            setTableLoading(true);        
            const response = await apiPost(buildApiRoute(apiRoutes.templates.list), []);            

            if (response && response.status === 200) {
                setTemplates(response.data.sort(function (a, b) {
                    return +new Date(a.modifiedDate) - +new Date(b.modifiedDate);
                  })
                  .reverse());
            }
            else
            {
                logging.error("Failed to fetch templates, non success status code", { response: response });
                setHasError(true);
            }
            
            setTableLoading(false);
        }
       
        fetch();
    }, []);


    const onRequestApproval = async (template) => {

        var payload = {
            templateId: template?.id,
            templateName: template?.name,
            templateVersion: template?.version
        }

        const response = await apiPost(buildApiRoute(apiRoutes.approval.create), payload); 
        if (response && response.status === 201) 
        {
            setTemplates( templates.map(x =>(x.id === template.id ? {...x, status: response.data.status} : x)));
        }
        else
        {
            logging.error("Failed to create the template, non success status code", { response: response });
            setHasError(true);
        }
    }

    const onCancelApproval = async (template) => 
    {
        var payload = {
            id : null,
            templateId: template?.id,            
            status: approvalStatus.Cancelled,
            version: template?.version
        }

        const response = await apiPut(buildApiRoute(apiRoutes.approval.update), payload);
        
        if (response && response.status === 200) {
            setTemplates( templates.map(x =>(x.id === template.id ? {...x, status: approvalStatus.Cancelled} : x)));
        }
        else
        {
            logging.error("Failed to cancel approval request, non success status code", { response: response });
            setHasError(true);
        }
    }
    const onDuplicate=async (template) =>{
        
        setTableLoading(true);  
        const response = await apiGet(buildApiRoute(apiRoutes.templates.duplicate,{id:template?.id}));
        if (response) {
           
            const datum=response.data;
            
            setTemplates(templates=>[...templates,datum]);

        } else {
            logging.error("Failed to duplicate the template, non success status code", { response: response });
            setHasError(true);
        }
        setTableLoading(false);  
    }
    
    const renderButton =(template) => 
    {
        switch(template?.status)
        {
            case approvalStatus.Approved:
                return <></>;
            case approvalStatus.InReview:
                return <></>;
            case approvalStatus.Pending:
                return  <>
                <Menu.Item key={`MI-2`}  onClick={ () => onCancelApproval(template) } >
                    <Button size='small' type='primary' iconName='Cancel' style={{ marginRight: 5 }} />
                    <span>Cancel approval</span>
                </Menu.Item>  
            </> 
            //If Unpublished y Declined and Cancelled
            default:                
                return <>
                    <Menu.Item key={`MI-2`}  onClick={ () => onRequestApproval(template) } >
                        <Button size='small' type='primary' iconName='DocumentApproval' style={{ marginRight: 5 }} />
                        <span>Request approval</span>
                    </Menu.Item>  
                </>
        
         }
    }

    const columns = [
        {
            title: 'Template name',
            key: 'name',
            render: (template) => (
                <Link to={buildAppRoute(appRoutes.template.view, { id: template?.id })}>
                    {template?.name}
                </Link>
            )
        },
        {
            title: 'Version',
            key: 'version',
            dataIndex: 'version'
        },
        {
            title: 'Publication status',
            key: 'status',
            dataIndex: 'status'
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (template) => {
                const menu = (                   
                    <Menu>
                        <Menu.Item key={`MI-1`} onClick={ () => navigate(buildAppRoute(appRoutes.template.manage, { templateId: template?.id }))}>                           
                            <Button size='small' type='primary' iconName='Settings' style={{ marginRight: 5 }} />
                            <span> Manage</span>
                        </Menu.Item> 
                        <Menu.Item key={`MI-3`} onClick={()=>onDuplicate(template)}>                           
                            <Button size='small' type='primary' iconName='DuplicateRow' style={{ marginRight: 5 }} />
                            <span> Duplicate</span>
                        </Menu.Item>                     
                        {renderButton(template)}                    
                    </Menu>
                );
                return (
                    <>
                    <Dropdown overlay={menu}>
                        <a>Actions</a>
                    </Dropdown>                   
                    </>
                )
            }
        }
    ];

    if (hasError) {
        return (
            <p>Error communicating with the server, try refreshing the page.</p>
        );
    }

    return (
        <>
            <h1>My Templates</h1>
            <div className='controls'>
                <Input
                    className='margin-tb'
                    type='text'
                    placeholder='Search existing'
                    onChange={({ target: { value } }) => {
                        setSearch(value);
                    }}
                />
                <Button
                type='primary'
                onClick={() => navigate(buildAppRoute(appRoutes.template.new))}>
                New
            </Button>
            </div>
           
            <Table
                columns={columns}
                loading={tableLoading}
                dataSource={templates?.filter(
                    (val) =>
                        !search ||
                        val.name
                            .toLowerCase()
                            .includes(search.toLocaleLowerCase())
                )}
                rowKey='id'
            />
        </>
    );
}
