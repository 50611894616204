import { SurveyCreator } from "survey-creator-react";

// hide properties from the property grid
export function hidePropertyGridProperties(creator) {
    
    const blackList = [
        'clearInvisibleValues',
        'sendResultOnPageNext',
        'clearIfInvisible',
        'navigateToUrl',
        'showCompletedPage',
        'completedHtml',
        'navigateToUrlOnCondition',
        'completedHtmlOnCondition',
        'loadingHtml',
        'completedBeforeHtml',
        'storeOthersAsComment',
    ];

    creator.onShowingProperty.add(function (sender, options) {
        options.canShow = blackList.indexOf(options.property.name) < 0;
    });
}