import * as SurveyPDF from 'survey-pdf';

export class CustomPdfBrick extends SurveyPDF.PdfBrick {
    
    async renderInteractive() {
        
      const doc = this.controller.doc;
      
      let newVal="";
      this.question.value.reverse();
        for(let k=0;k<this.question.value.length;k++)
        {
            newVal=newVal+this.question.value[k].FileName;
            if(k<this.question.value.length-1)
            {
                newVal=newVal + "\n";
            }
        }

        var options={
            horizontalAlign:"center",
            isBold:false,
            margins:5,
            verticalAlign:"bottom"
        };
      doc.text(newVal,this.xLeft+5,this.xRight,options,"F");
      
    }
  }
  export class FlatCustomQuestion extends SurveyPDF.FlatQuestion {
    async generateFlatsContent(point) {
       
      const rect = { ...point };
      rect.yBot = point.yTop + 20;
      rect.xRight = point.xLeft + 100;
      return [new CustomPdfBrick(this.question, this.controller, rect)];
    }
  }