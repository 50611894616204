import React from "react";
import * as Capture from "../Capture";
import { captureActions, captureManageTabs } from "../../config/enum";

export default function Tab({ capture, view, disableTabs, setCapture }) {
  if (view.tab === captureManageTabs.Settings) {
    switch (view.action) {
      case captureActions.Create:
        return <Capture.Create />;
      case captureActions.Manage:
        return <Capture.Update capture={capture} disableTabs={disableTabs} setCapture={setCapture} />;
      default:
        return <Capture.Update capture={capture} disableTabs={disableTabs} setCapture={setCapture} />;
    }
  }
  return <></>
}
