import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { appInsightsReactPlugin } from "./components/insights/AppInsights";
import AppRoutes from './components/routing/AppRoutes';
import AppLayout from './components/layout/AppLayout';
import AppAuth from './components/auth/AppAuth';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './config/msal';
import ErrorBoundary from './components/error/ErrorBoundary';

import '@bdo/kitchensink/lib/styles/index.css';
import './styles/main.scss';

export function App() {

    const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
    const msalInstance = new PublicClientApplication(msalConfig);

    return (
        <AppInsightsContext.Provider value={appInsightsReactPlugin}>
            <BrowserRouter basename={baseUrl}>
                <ErrorBoundary>
                    <MsalProvider instance={msalInstance}>
                        <AppAuth>
                            <AppLayout>
                                <AppRoutes />
                            </AppLayout>
                        </AppAuth>
                    </MsalProvider>
                </ErrorBoundary>
            </BrowserRouter>
        </AppInsightsContext.Provider>
    );
}
