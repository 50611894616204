// configure the toolbox
export function configureToolbox(creator) {
    const blackList = [
        "file",
        "image",
        "imagepicker",
        "signaturepad",
    ]

    for (let i = 0; i < blackList.length; i++) {
        creator.toolbox.removeItem(blackList[i]);
    }

    creator.toolbox.changeCategories([
        { name: "panel", category: "Panels" },
        { name: "paneldynamic", category: "Panels" },
        { name: "matrix", category: "Matrixes" },
        { name: "matrixdropdown", category: "Matrixes" },
        { name: "matrixdynamic", category: "Matrixes" },
        { name: "html", category: "Advanced" },
        { name: "expression", category: "Advanced" },
    ]);

    creator.toolbox.keepAllCategoriesExpanded = true;
}