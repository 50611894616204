export function reduceStringCommaDelimited(value) {
    let items = value.split(',');
    if (items.length >= 3) {
        return `${
            items[0]
        }, ${
            items[1]
        }, ${
            items[2]
        }...`
    }
    return value;
}

export function downloadJson(objectData, filename) {

    let contentType = "application/json;charset=utf-8;";

    var jsonDoc = document.createElement('a');
    jsonDoc.download = filename;
    jsonDoc.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(objectData));
    jsonDoc.target = '_blank';
    document.body.appendChild(jsonDoc);
    jsonDoc.click();
    document.body.removeChild(jsonDoc);
}

export function flattenObject(obj) {
    let flattenKeys = {};
    for (let i in obj) {
        if (! obj.hasOwnProperty(i)) 
            continue;
        
        if (typeof obj[i] == 'object') {
            // flattenKeys[i] = obj[i];
            let flatObject = flattenObject(obj[i]);
            for (let j in flatObject) {
                if (! flatObject.hasOwnProperty(j)) 
                    continue;
                
                flattenKeys[i + '.' + j] = flatObject[j];
            }
        } else {
            flattenKeys[i] = obj[i];
        }
    }
    return flattenKeys;
}

export function convertToCSV(objArray, headerList) {

    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'Response,';

    for (let index in headerList) {
        row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
        let line = (i + 1) + '';
        for (let index in headerList) {
            let head = headerList[index];
            let item = array[i][head];
            if (item !== undefined) {
                item = item.toString().replace(/,/g, ' ');
                line += ',' + item;
            } else {
                line += ',' + '';
            }
        }
        str += line + '\r\n';
    }
    return str;
}

export function donwloadCSV(csv, filename) {

    const blob = new Blob([csv], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
