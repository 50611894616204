import { generalMessage } from "./errorMessages";

//Reuseable function to validate the emails on the form applied, to check if the emails exceed the max length (10 emails).
export const EmailsValidator = {
    message: generalMessage.EmailValidation,
    validator: (_, value) => {
        // fix #105417
        if (!!value) {
            if (value.split(',').length <= 10) {
                return Promise.resolve();
            } else {
                return Promise.reject(generalMessage.EmailValidation);
            }
        } else {
            return Promise.resolve();
        }
     }
}  

export const InputLengthValidator = {
  message: generalMessage.InputLengthValidation,
  validator: (_, value) => {
    if (value.length <= 500) {
      return Promise.resolve();
    } else {
      return Promise.reject(generalMessage.InputLengthValidation);
    }
   }
}  