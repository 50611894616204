import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import qs from 'qs';
import { scopesRequest } from '../config/msal';
import { InteractionRequiredAuthError  } from '@azure/msal-browser'

export function useCaptureApi() {
    const { instance, accounts } = useMsal();

    async function getAccessToken() {
        let tokenResponse;

        let authRequest = {
            scopesRequest,
            account: accounts[0] || {}
        };

        try {
            tokenResponse = await instance.acquireTokenSilent(authRequest);
        }
        catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                tokenResponse = instance.acquireTokenRedirect(authRequest);
            }
            else {
                throw (error);
            }
        }
        return tokenResponse;
    }

    async function apiRequest(type, route, body, query, isFileDownload=false) {
        body = body || null;
        query = query || null;
        route = `${route}${query ? `?${qs.stringify(query, { allowDots: true })}` : ''}`;
        
         let tokenResponse;

        let authRequest = {
            scopesRequest,
            account: accounts[0] || {}
        };

        try {
            tokenResponse = await instance.acquireTokenSilent(authRequest);
        }
        catch (error) {
            if (error instanceof InteractionRequiredAuthError) {
                tokenResponse = instance.acquireTokenRedirect(authRequest);
            }
            else {
                throw (error);
            }
        }

        return await axios.request({
            headers: {
                Authorization: `Bearer ${tokenResponse.accessToken}`,
                'Content-Type': 'application/json'
            },
            responseType: isFileDownload? "blob" :"json",
            method: type,
            url: route,
            data: body,
            validateStatus: function (status) 
            {
                return status < 500
            }            
        });
    }

    async function apiGet(route, query, isFileDownload) {
        return await apiRequest('get', route, null, query, isFileDownload);
    }

    async function apiPut(route, body, query) {
        return await apiRequest('put', route, body, query);
    }

    async function apiPost(route, body, query) {
        return await apiRequest('post', route, body, query);
    }

    async function apiPostByteArray(route, body, query) {
        return await apiRequest('post', route, body, query);
    }

    async function apiPatch(route, body, query) {
        return await apiRequest('patch', route, body, query);
    }

    async function apiRemove(route, query) {
        return await apiRequest('delete', route, null, query);
    }

    return { apiGet, apiPut, apiPost, apiPatch, apiRemove };
}
