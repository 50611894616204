import React from 'react';

export default function Loading() {
    return (
        <div className='bdo-page'> 
                <header className='header-image bdo-spinner-container'>
                    <div className="bdo-spinner"/>                 
                </header>
                <p>Please wait...</p>
                <footer>                
                </footer>
            </div>
    );
}