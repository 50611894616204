export class CustomError extends Error {
    errorType;
    errorCode;
    additionalInfo;

    constructor({ errorType, errorCode, additionalInfo, message }) {
        super(message);
        this.errorType = errorType;
        this.errorCode = errorCode;
        this.additionalInfo = additionalInfo;
    }
}