import MarkdownIt from "markdown-it";
import attrs from "markdown-it-attrs";

// add markdown support in builder
export function addMarkdownSupport(creator) {
  const markdownConverter = new MarkdownIt();
  markdownConverter.use(attrs, {
    allowedAttributes: ["target"],
  });

  creator.onSurveyInstanceCreated.add(function (sender, options) {
    if (options.reason === "test") {
      options.survey.onTextMarkdown.add(function (survey, options) {
        if (options.name !== "title") {
          options.html = markdownConverter.render(options.text);
        }
      });
    }
  });
}
