import React from "react";
import * as GlobalPortal from "../GlobalPortal";
import { captureManageTabs, globalPortalActions } from "../../config/enum";

export default function Tab({ capture, view, setView }) {
  if (view.tab === captureManageTabs.GlobalPortal) {
    switch (view.action) {
      case globalPortalActions.List:
        return <GlobalPortal.List view={view} setView={setView} />;
      case globalPortalActions.Create:
        return <GlobalPortal.Create capture={capture} view={view} setView={setView} />;
      case globalPortalActions.View:
        return <GlobalPortal.View view={view} setView={setView} />;
      case globalPortalActions.Update:
        return <GlobalPortal.Update capture={capture} view={view} setView={setView} />;
      default:
        return <GlobalPortal.List view={view} setView={setView} />;
    }
  }
  return <></>
}
