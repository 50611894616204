import { Button, Input, Alert } from "@bdo/kitchensink";
import React, { useEffect, useState } from "react";
import Table from "./Table";
import BackButton from "../misc/BackButton";
import { appRoutes, buildAppRoute } from "../../config/appRoutes";
import { useLogging } from "../../hooks/useLogging";
import { captureManageTabs, globalPortalActions } from "../../config/enum";
import { useGlobalPortalAssigment } from "../../hooks/useGlobalPortalAssigment";
import { useParams } from "react-router-dom";

export default function List({ setView }) {
  const { captureId } = useParams();
  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const logging = useLogging();
  const [search, setSearch] = useState("");
  const { GetAssignmentsForCapture } = useGlobalPortalAssigment();

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        setHasError(false);
        const result = await GetAssignmentsForCapture(captureId);
        if (result && result.status === 200) {          
          setRecords(result.data
            .sort(function (a, b) {
              return +new Date(a.modifiedDate) - +new Date(b.modifiedDate);
            })
            .reverse());
          setIsLoading(false);
          return;
        }
        setHasError(true);
        logging.error("Failed to fetch assigments, non success status code", { result: result });
      } catch (ex) {
        setHasError(true);
        logging.error("Failed to fetch assigments, non success status code", { exception: ex });
      }

      setIsLoading(false);
    }
    fetchData();
  }, [captureId]);

  return (
    <>
      {hasError && <Alert type="error" message="There was an issue fetching records, please try refreshing the page." className="mb-3" />}
      <h2>Search existing <span className='highlighted-text'>Global Portal Capture</span> responses</h2>
      <div className="controls">
        <Input
          className="margin-tb"
          type="text"
          placeholder="Search by portal"
          onChange={({ target: { value } }) => {
            setSearch(value);
          }}
        />
        <Button
          type="primary"
          onClick={() =>
            setView({
              tab: captureManageTabs.GlobalPortal,
              action: globalPortalActions.Create,
            })
          }
        >
          New
        </Button>
      </div>
      <Table isLoading={isLoading} records={records} filterTerm={search} setView={setView} captureId={captureId} />

      <div className="d-flex flex-row justify-content-between mt-3">
        <BackButton url={buildAppRoute(appRoutes.capture.list)}>Back</BackButton>
      </div>
    </>
  );
}
