import { CustomError } from "./CustomError";
import { ErrorTypes } from "./ErrorTypes";

export class ApplicationError extends CustomError {
    constructor(args) {
        super({
            errorType: ErrorTypes.application,
            ...args
        });
    }
}