import { useCaptureApi } from "./useCaptureApi";
import { apiRoutes, buildApiRoute } from "../config/apiRoutes";

export function useExternalCaptureData() {

  const { apiGet } = useCaptureApi();

  async function GetExternalCapturesForCaptureId(captureId) {
    return await apiGet(buildApiRoute(apiRoutes.externalCapture.list, { externalCaptureId: captureId }));
  }

  return { GetExternalCapturesForCaptureId };
}