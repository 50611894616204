import React from "react";
import * as Passcode from "../Passcode";
import { captureManageTabs, passcodeActions } from "../../config/enum";

export default function Tab({ capture, view, setView }) {
  if (view.tab === captureManageTabs.Passcodes) {
    switch (view.action) {
      case passcodeActions.View:
        return <Passcode.View capture={capture} view={view} setView={setView} />;
      case passcodeActions.List:
        return <Passcode.List capture={capture} view={view} setView={setView} />;
      case passcodeActions.Create:
        return <Passcode.Create view={view} setView={setView} />;
      case passcodeActions.Update:
        return <Passcode.Update view={view} setView={setView} />;
      default:
        return <Passcode.List capture={capture} view={view} setView={setView} />;
    }
  }
  return <></>
}
