export const approvalStatus = {
  Pending: "Pending",
  Approved: "Approved",
  Declined: "Declined",
  Cancelled: "Cancelled",
  Unpublished: "Unpublished",
  InReview: "In review",
};

export const statuses = {
  NotStarted: "Not started",
  InProgress: "In progress",
  Complete: "Complete",
  Reopened: "Reopened",
};

export const captureActions = {
  Create: "create",
  Manage: "manage",
};

export const passcodeActions = {
  List: "list",
  Create: "create",
  Update: "update",
  View: "view",
};

export const globalPortalActions = {
  List: "list",
  Create: "create",
  Update: "update",
  View: "view",
};

export const responseActions = {
  List: "list",
  View: "view",
  Passcode: "passcode",
};

export const captureManageTabs = {
  Settings: "settings",
  Passcodes: "passcode", // this matches the route segment in appRoutes
  GlobalPortal: "gp", // this matches the route segment in appRoutes
};
