import { useCaptureApi } from "./useCaptureApi";
import { apiRoutes, buildApiRoute } from "../config/apiRoutes";

export function useResponseData() {

  const { apiGet } = useCaptureApi();

  async function GetPDFInfo(responseId) {
    return await apiGet(buildApiRoute(apiRoutes.responses.pdf, { id: responseId }));
  }

  return { GetPDFInfo };
}