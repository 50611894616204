import { CustomError } from "./CustomError";
import { ErrorTypes } from "./ErrorTypes";

export class ApiError extends CustomError {

    httpStatusCode;

    constructor(args) {
        super({
            errorType: ErrorTypes.api,
            ...args
        });
        this.httpStatusCode = args.httpStatusCode;
    }
}