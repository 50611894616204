import { Button, Input, Form, Alert, Switch } from "@bdo/kitchensink";
import React, { useState } from "react";
import { captureManageTabs, passcodeActions } from "../../config/enum";
import { EmailsValidator } from "../../config/formValidations";
import MetaDataSurvey from "../shared/metaDataSurvey/MetaDataSurvey";

export default function PasscodeForm({ errorMessage, onSave, hasMetaData = false, CreateMultiplePasscodeEnable = false, templateSchema = {}, templateData = {}, passcode = {}, setErrorMessage, setView, ...props }) {
  const validateMessages = {
    required: "${label} is required.", // eslint-disable-line no-template-curly-in-string
  };

  const [form] = Form.useForm();
  const [sendNotification, setSendNotification] = useState(passcode.sendCompletionNotification ? passcode.sendCompletionNotification : false);
  const [isSaving, setIsSaving] = useState(false);
  const [survey, setSurvey] = useState({});
  const [sendRecipientPasscodeNotification, setSendRecipientPasscodeNotification] = useState(passcode.sendRecipientPasscodeNotification);
  const [displayMultipleSwitch, setDisplayMultipleSwitch] = useState(false);
  const [multiPasscode, setMultiPasscode] = useState(false);

  function onAfterRenderSurvey(sender, options) {
    setSurvey(sender);
  }

  async function onFinish(values) {
    setIsSaving(true);

    if (hasMetaData && survey.hasErrors(true, true)) {
      setIsSaving(false);
    } else {
      if (!sendNotification) {
        values["completionEmailAddresses"] = null;
      }

      let result = await onSave(values);
      if (result === false) {
        setIsSaving(false);
      }
    }
  }

  async function onSendRecipientPasscodeNotificationChange(checked) {
    setSendRecipientPasscodeNotification(checked);
  }

  async function sendRecipientPasscodeNotificationToggle() {
    //We turn send recipient passcode notificaiton on, based on when creating multiple passcode the users always want to received an emails.
    form.setFieldsValue({
      'sendRecipientPasscodeNotification': !form.sendRecipientPasscodeNotification
    })  
    onSendRecipientPasscodeNotificationChange(!form.sendRecipientPasscodeNotification);
  }

  return (
    <>
      {errorMessage && <Alert type="error" message={errorMessage} className="mb-3" />}

      <Form form={form} layout="vertical" name="external-passcode" onFinish={onFinish} validateMessages={validateMessages} initialValues={passcode}>
        {/* #103711 reorder questions */}
        {hasMetaData && (
          <Form.Item name={["metaData"]} valuePropName="value">
            <MetaDataSurvey templateSchema={templateSchema} templateData={templateData} onAfterRenderSurvey={onAfterRenderSurvey} />
          </Form.Item>
        )}
        { CreateMultiplePasscodeEnable && 
          <>
            <Form.Item 
             name={["multipleCreation"]}
             label="Create Multiple Passcodes">
              <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={ () => { 
              setDisplayMultipleSwitch ((prev) => !prev); 
              sendRecipientPasscodeNotificationToggle();
              setMultiPasscode((prev) => !prev); }} />
            </Form.Item>
            {multiPasscode && (
              <Form.Item
                name={["multiplePasscodesNumber"]}
                hidden= {!displayMultipleSwitch}
                label="How many passcodes do you need generated?"
                rules={[{ required: true }, () => ({
                  validator(rule, value) {              
                    if (value < 0 || value > 50 ) {               
                      return Promise.reject("The number of passcodes should be between 1 and 50.");              
                    } else {
                      return Promise.resolve();
                    }
                  }
                })]}          
              >
                <Input placeholder="50" type="number" />
              </Form.Item>
            )}
          </>
        }
        <Form.Item
          name={["tag"]}
          label="Reference name"
          rules={[{ required: true }, { max: 200 }]}
          extra="A reference that can be used to distinguish this passcode from others e.g. Client 12345. It must be unique per capture."
        >
          <Input placeholder="Client 12345" />
        </Form.Item>
        <Form.Item
          name={["internalComments"]}
          label="Internal comments"
          rules={[{ required: true }, { max: 5000 }]}
          extra="A field to add some internal BDO comments relating to this passcode e.g. the purpose of sending the passcode to the recipient."
        >
          <Input.TextArea placeholder="BDO internal notes" style={{ height: 120 }} />
        </Form.Item>
        <Form.Item
          name={["sendCompletionNotification"]}
          label="Send a notification upon completion?"
          valuePropName="checked"
          extra="If selected an email will be sent to chosen email addresses when this passcode is completed. The email will contain a link to access the response associated with this passcode."
        >
          <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={setSendNotification} />
        </Form.Item>
        {sendNotification && (
          <Form.Item
            name={["completionEmailAddresses"]}
            label="Completion emails"
            rules={[{ required: sendNotification }, EmailsValidator]}
            extra="A comma (,) seperated list of email addresses that are the intended recipient(s) for a passcode completed notification. Maximum 10 emails."
          >
            <Input placeholder="employee.name@bdo.co.uk" type="email" multiple />
          </Form.Item>
        )}

        <Form.Item
          name={["recipientEmailAddresses"]}
          label="Recipient emails"
          rules={[{ required: true }, EmailsValidator]}
          extra="A comma (,) seperated list of email addresses that are the intended recipient for this passcode. Minimum 1, Maximum 10 emails."
        >
          <Input placeholder="client.name@company.com" type="email" multiple />
        </Form.Item>
        
        <Form.Item
          name={["sendRecipientPasscodeNotification"]}
          label="Email passcode to recipient?"
          valuePropName="checked"
          extra="If selected an email will be sent to the recipient(s) entered above upon saving this form. The email will contain a unique passcode and a link to the capture."
        >
          <Switch checkedChildren="Yes" unCheckedChildren="No" onChange={onSendRecipientPasscodeNotificationChange} />
        </Form.Item>

        <Form.Item>
          <div className="d-flex flex-row justify-content-between">
            <Button type="default" onClick={() => setView({ tab: captureManageTabs.Passcodes, action: passcodeActions.List })}>
              Cancel
            </Button>
            <Button className="ms-3" iconName="Save" type="success" htmlType="submit" loading={isSaving}>
              {sendRecipientPasscodeNotification ? "Save and send" : "Save"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
}
