import React, { useEffect, useState } from "react";
import { useLogging } from "../../hooks/useLogging";
import { KitchensinkSurvey } from "../shared/survey/KitchensinkSurvey";
import { captureManageTabs, globalPortalActions } from "../../config/enum";
import BackButton from "../misc/BackButton";
import * as Shared from "../Shared";
import { useGlobalPortalAssigment } from "../../hooks/useGlobalPortalAssigment";
import { useParams } from "react-router-dom";

export default function View({ view, setView }) {
  const { memberFirmId, clientId, projectId, assignmentId } = useParams();
  const { LoadAssignment } = useGlobalPortalAssigment();
  const [record, setRecord] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const logging = useLogging();
  const [notFound, setNotFound] = useState(false);
  const currentMemberFirmId = memberFirmId !== undefined ? memberFirmId : view.record.memberfirmId;
  const currentClientId = clientId !== undefined ? clientId : view.record.clientId;
  const currentProjectId = projectId !== undefined ? projectId : view.record.projectId;
  const currentAssignmentId = assignmentId !== undefined ? assignmentId : view.record.id;

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        setHasError(false);
        const result = await LoadAssignment(currentMemberFirmId, currentClientId, currentProjectId, currentAssignmentId);
        if (result && result.status === 200) {
          setRecord(result.data);
          setIsLoading(false);
          return;
        }

        if (result && result.status === 404) {
          setNotFound(true);
          setIsLoading(false);
          return;
        }
        setHasError(true);
        logging.error("Failed to fetch the response, non success status code", { result: result });
      } catch (ex) {
        logging.error("Failed to fetch the response, non success status code", { exception: ex });
        setHasError(true);
      }

      setIsLoading(false);
    }
    fetchData();
  }, [currentAssignmentId]);

  if (isLoading) {
    return <Shared.Loading />;
  }

  if (hasError) {
    return <Shared.Error />;
  }

  if (notFound) {
    return <Shared.NotFound />;
  }

  return (
    <div>
      <KitchensinkSurvey template={record.surveyModel} data={record.userData} mode="display" />
      <div className="d-flex justify-content-between">
        <BackButton type="danger" onClick={() => setView({ tab: captureManageTabs.GlobalPortal, action: globalPortalActions.List })}>
          Back
        </BackButton>
      </div>
    </div>
  );
}
