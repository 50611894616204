import { Alert } from '@bdo/kitchensink';
import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { apiRoutes, buildApiRoute } from '../../config/apiRoutes';
import * as SurveyCreatorReact from "survey-creator-react";
import { useLogging } from '../../hooks/useLogging';
import { useCaptureApi } from '../../hooks/useCaptureApi';
import * as Shared from '../Shared'
import { ContentLayoutContext, contentLayouts } from '../layout/ContentLayoutContext';
import { configureSerializer } from './configuration/configure-serializer';
import { configureCreator } from "./configuration/configure-creator";
import { approvalStatus } from '../../config/enum'
import { registerFileUpload } from '../fileUpload/SurveyCustomComponent';
import "survey-core/defaultV2.css";
import "survey-creator-core/survey-creator-core.css";
import "survey-core/survey.i18n";

registerFileUpload();

export default function Builder() {

    const states = {
        error: 'Error',
        notFound: 'NotFound',
        loading: 'Loading',
        accessDenied: 'AccessDenied',
        loaded: 'Loaded'
    }

    const renderState = {
        [states.loading]: () => {
            return (<Shared.Loading />);
        },
        [states.error]: () => {
            return (<Shared.Error />);
        },
        [states.notFound]: () => {
            return (<Shared.NotFound />);
        },
        [states.accessDenied]: () => {
            return (<Shared.AccessDenied />);
        },
        [states.loaded]: () => {
            return (
                <>
                    {errorMessage && <Alert type="error" message={errorMessage} className="mb-3" />}
                    {surveyCreatorRef.current && <SurveyCreatorReact.SurveyCreatorComponent creator={surveyCreatorRef.current} />}
                </>
            );
        },
    }

    const { apiGet, apiPost } = useCaptureApi();
    const { id } = useParams();
    const logging = useLogging();
    const templateIdRef = useRef(id);
    const surveyCreatorRef = useRef(null);
    const [componentState, setComponentState] = useState(states.loading);
    const { setContentLayout } = useContext(ContentLayoutContext);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setContentLayout(contentLayouts.fullWidth)
        return (() => {
            setContentLayout(contentLayouts.default);
        });
    }, [])

    useEffect(() => {
        async function fetchTemplate() {
            try {
                if (templateIdRef.current) {
                    const result = await apiGet(buildApiRoute(apiRoutes.templates.view, { id: templateIdRef.current }));

                    if (result && result.status === 200) {
                        processTemplate(result.data);
                        setComponentState(states.loaded);
                    }

                    if (result && (result.status === 401 || result.status === 403)) {
                        setComponentState(states.accessDenied);
                    }

                    if (result && result.status === 404) {
                        setComponentState(states.notFound);
                    }

                    if (result && ![200, 401, 403, 404].includes(result.status)) {
                        setComponentState(states.error);
                        logging.error("Failed to load template, non success status code", { result: result });
                    }
                }
                else {
                    setComponentState(states.loaded);
                }
            }
            catch (ex) {
                setComponentState(states.error);
                logging.error("Failed to load template, axios exception", { exception: ex });
            }
        }

        // check for an unsaved backup
        const backup = localStorage.getItem(getBackupId());

        if (backup) {
            const prompt = "An unsaved template has been recovered from your browser storage. Click 'OK' to load the backup or 'Cancel' to continue without the backup."

            if (window.confirm(prompt)) {
                surveyCreatorRef.current.JSON = JSON.parse(backup);
                surveyCreatorRef.current.setState("modified"); // enable the save button
                setComponentState(states.loaded);
                return; // return early, dont fetch from server (user has chosen to use local backup)
            } else {
                localStorage.removeItem(getBackupId());
            }
        }

        // attempt to load template from server
        fetchTemplate();
    }, []);

    const getBackupId = useCallback(() => {
        return templateIdRef.current ?? "unsaved-template";
    });

    const processTemplate = useCallback((template) => {
        templateIdRef.current = template.id;
        surveyCreatorRef.current.JSON = template.data;
        surveyCreatorRef.current.survey.checkErrorsMode = "onComplete";
        if (template.status === approvalStatus.Pending || template.status === approvalStatus.InReview) {
            surveyCreatorRef.current.readOnly = true;
        }
    })

    // create and configure the survey creator once
    if (surveyCreatorRef.current === null) {
        configureSerializer();

        const creator = new SurveyCreatorReact.SurveyCreator({
            showLogicTab: true,
            showJSONEditorTab: window.location.hostname === 'localhost',
            showTranslationTab: false            
        });
        
        creator.survey.checkErrorsMode ="onComplete";
        creator.haveCommercialLicense = true;

        configureCreator(creator);

        creator.saveSurveyFunc = async (saveNo, callback) => {
            try {
                const payload = {
                    name: surveyCreatorRef.current.JSON.title ?? 'Survey Title',
                    data: surveyCreatorRef.current.JSON
                };

                if (templateIdRef.current) {
                    payload.id = templateIdRef.current;
                }

                const result = await apiPost(
                    buildApiRoute(templateIdRef.current ? apiRoutes.templates.update : apiRoutes.templates.create),
                    payload);

                if (result && (result.status === 200 || result.status === 201)) {
                    callback(saveNo, true);
                    processTemplate(result.data);
                    if (result.status === 201) {
                        window.history.replaceState(null, null, `${window.location.href}/${result.data.id}`);
                        localStorage.removeItem("unsaved-template");
                    } else {
                        localStorage.removeItem(result.data.id);
                    }
                    setErrorMessage("");
                    return;
                }
                else if (result.status === 400) {
                    callback(saveNo, false);
                    setErrorMessage(result.data);
                    return;
                }
                else {
                    callback(saveNo, false);
                    logging.error("Failed to save template, non success status code", { result: result });
                    return;
                }
            }
            catch (ex) {
                callback(saveNo, false);
                logging.error("Failed to save template, axios exception", { exception: ex });
            }
        }

        creator.onModified.add((sender, options) => {
            localStorage.setItem(getBackupId(), JSON.stringify(sender.JSON));
        });

        surveyCreatorRef.current = creator;
    }

    // Render    
    return (renderState[componentState]());
}