import { useEffect } from 'react';
import { scopesRequest } from '../../config/msal';
import { MsalAuthenticationTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus } from "@azure/msal-browser";
import Error from './Error';
import Loading from './Loading';

export default function AppAuth(props) {

    const isAuthenticated = useIsAuthenticated();
    const { inProgress } = useMsal();

    function getMidnight() {
        var date = new Date();
        date.setHours(23, 59, 59);
        return date;
    }

    function invalidateStorage(key) {
        let expiresString = localStorage.getItem(key);
        let expiresDate;
        let now = new Date();
        let midnight = getMidnight();

        if (expiresString) {
            expiresDate = new Date(expiresString);
        }

        if (expiresString === null || expiresDate < now) {
            localStorage.clear();
            sessionStorage.clear();
            localStorage.setItem(key, midnight);
        }
    }

    invalidateStorage('cache-expires');

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={scopesRequest}
            errorComponent={Error}
            loadingComponent={Loading}>
            {(isAuthenticated && (inProgress !== InteractionStatus.Login)) && <>{props.children}</>}
        </MsalAuthenticationTemplate>
    );
}