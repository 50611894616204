import React from "react";
import { Button } from "@bdo/kitchensink";
import styles from './ErrorPage.module.scss';
import { HomeFilled } from '@ant-design/icons';
import { ApiError } from "../../errors/ApiError";
import { ApplicationError } from "../../errors/ApplicationError";

export default function ErrorPage({
  error,
  onClick
}) {

  const renderTemplate = ({ heading = "Oops! Something went wrong.", errorCode = "UNE0001" } = {}) => {
    return (
      <>
        <h3>{heading}</h3>
        <p>Error: {errorCode}</p>
        <p>Please try again, if you continue to experience issues then please contact <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>Capture support</a>.</p>
      </>
    );
  }

  const renderMessage = () => {
    if (error instanceof ApiError) {
      switch (error.httpStatusCode) {
        case 401:
        case 403:
          return renderTemplate({
            heading: "Access Denied - You do not have sufficient privileges to access this resource.",
            errorCode: error.errorCode,
          });
        case 404:
          return renderTemplate({
            heading: "Not Found - The requested resource could not be found.",
            errorCode: error.errorCode,
          });
        default:
          return renderTemplate({ errorCode: error.errorCode });
      }
    }

    if (error instanceof ApplicationError) {
      return renderTemplate({ errorCode: error.errorCode });
    }

    if (error instanceof Error) {
      return renderTemplate();
    }
  }

  return (
    <div className={styles.errorPage}>
      <div className={styles.errorContainer}>
        <div className={styles.warningIcon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30.52 26.69"
            fill="#98002e"
          >
            <path d="M29.69,26.64H.88a.89.89,0,0,1-.76-.44.85.85,0,0,1,0-.87L14.52.38A.91.91,0,0,1,15.79.17,1,1,0,0,1,16,.38l14.41,25a.88.88,0,0,1-.76,1.31ZM2.39,24.89H28.17L15.28,2.56Zm14.39-8V7.41h-3V19Zm-3,4.24v2.3h3V19Z" />
          </svg>
        </div>
        {renderMessage()}
        <p>
          <Button
            type="primary"
            icon={<HomeFilled />}
            onClick={onClick}
          >
            RETURN HOME
          </Button>
        </p>
        <p className={styles.appVersion}>CBO v{process.env.REACT_APP_VERSION}</p>
      </div>
    </div>
  );
};
