import { Col, Row } from '@bdo/kitchensink';
import React from 'react';
import CaptureForm from './CaptureForm';

export default function Create() {

    return (
        <div className='content' style={{ paddingTop: '30px' }}>
            <Row>
                <Col span={24}>
                <CaptureForm 
                    title={
                        <>
                            <h2>Create a new <span className='highlighted-text'>Capture</span></h2>
                        </>
                    }
                    isUpdating= {false}
                />                   
                </Col>
            </Row>
        </div>
    );
}